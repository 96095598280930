import React from 'react';

const kvkk = () => {
  return (
    <div className='txt-sm no-pad'>
      <div className='txt-xs-2 no-pad txt-ctr'>
        <p>Sevgili Kullanıcımız,</p>
        <p>
          www.bukalemun.co internet sitesinin sahibi olan Dem Derneği olarak
          kişisel verilerinin gizliliği ve g&uuml;venliği bizim i&ccedil;in
          &ccedil;ok &ouml;nemli.
        </p>
        <p>
          Kullanıcımız olarak senin kişisel verilerini nasıl işlediğimizi ve
          6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında sahip
          olduğun hakları anlatmak istiyoruz.
        </p>
        <p>
          Bu nedenle aşağıda yer alan
          <strong>
            {' '}
            Kişisel Verilerinin İşlenmesine İlişkin Aydınlatma Metni
          </strong>
          &rsquo;ni seninle paylaşıyoruz.
        </p>
        <p>
          Eğer aşağıdaki metinde a&ccedil;ık olmadığını
          d&uuml;ş&uuml;nd&uuml;ğ&uuml;n veya a&ccedil;ıklamamızı istediğin bir
          konu olursa, bizimle yazılı olarak iletişime ge&ccedil;ebilirsin.
        </p>
        <p>
          Sorularına cevap verebilir, aydınlatma metnimizi soruların ışığında
          geliştirebiliriz.
        </p>
        <p>Sevgilerimizle,</p>
        <p>Dem Derneği</p>
      </div>
      <div className='txt-sm no-pad'>
        <p>
          <strong>Dem Derneği</strong>
        </p>
        <p>
          <strong>
            Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni
          </strong>
        </p>
        <p>
          Bukalemun kullanıcısı olarak yazılı, s&ouml;zl&uuml; veya elektronik
          olarak bizimle paylaştığınız t&uuml;m kişisel bilgileriniz bizim
          i&ccedil;in &ccedil;ok değerli. Kişisel verilerinizi, zorunlu
          olmadığımız hi&ccedil;bir durumda işlemiyoruz veya saklamıyoruz. Bir
          sivil toplum kuruluşu olarak, bizimle paylaştığın kişisel verilerini
          yalnızca belirli, a&ccedil;ık ve meşru bir neden varsa, yalnızca bu
          durumun gerektirdiği &ouml;l&ccedil;&uuml;de işliyor ve asgari
          s&uuml;rede muhafaza ediyoruz.
        </p>
        <p>
          <strong>1.Tanımlar</strong>
        </p>
        <p>
          Aydınlatma metnimizi daha sade tutmak ve anlam birliğini sağlamak
          i&ccedil;in aşağıda tanımladığımız ifadeler, metin i&ccedil;erisinde
          aşağıda yaptığımız tanımlardaki anlamları taşımaktadır.
        </p>
        <p>
          <strong>Kişisel Veri: </strong>Kimliği belirli veya belirlenebilir
          ger&ccedil;ek kişiye ilişkin her t&uuml;rl&uuml; bilgiyi ifade eder.
        </p>
        <p>
          <strong>
            Kişisel Verilerin Korunması Kanunu (&ldquo;KVKK&rdquo;):{' '}
          </strong>
          7 Nisan 2016 tarihinde Resmi Gazete&rsquo;de yayınlanarak
          y&uuml;r&uuml;rl&uuml;ğe giren 6698 sayılı Kişisel Verilerin Korunması
          Kanunu&rsquo;nu ifade eder.
        </p>
        <p>
          <strong>Bukalemun: </strong> Dem Derneği&rsquo;nin sahip olduğu{' '}
          <a href='http://www.bukalemun.co'>www.bukalemun.co </a>
          internet sitesini ifade eder.
        </p>
        <p>
          <strong>Veri Sorumlusu: </strong>
          Merkezi Tomtom Mah. Yeni&ccedil;arşı Cad. No: 68 / 21 Beyoğlu -
          İstanbul adresinde bulunan 34201034 k&uuml;t&uuml;k numaralı Dem
          Derneği&rsquo;ni ifade eder.
        </p>
        <p>
          <strong>2. Kişisel Verilerin İşlenme Amacı</strong>
        </p>
        <p>Her zaman belirttiğimiz gibi, kişisel verilerini;</p>
        <ul>
          <li>Hukuka ve d&uuml;r&uuml;stl&uuml;k kurallarına uygun şekilde,</li>
          <li>Doğru ve gerektiğinde g&uuml;ncel olarak,</li>
          <li>Belirli, a&ccedil;ık ve meşru ama&ccedil;lar i&ccedil;in,</li>
          <li>
            İşlendikleri ama&ccedil;la bağlantılı, sınırlı ve
            &ouml;l&ccedil;&uuml;l&uuml; olarak,
          </li>
        </ul>
        <p>
          işliyoruz. Ayrıca saklamamız gereken bir kişisel veri olursa, bunu
          yalnızca ilgili mevzuatta &ouml;ng&ouml;r&uuml;len veya amacımızla
          sınırlı asgari s&uuml;re boyunca muhafaza ediyoruz.
        </p>
        <p>
          Verilerini işlememizdeki temel ama&ccedil;, senin platformumuz
          Bukalemun.co&rsquo;dan daha efektif faydalanabilmendir. Bu nedenle
          sitemizde anonim olarak saklanan &ccedil;erezler kullanabiliriz.
          Bizimle paylaştığın &uuml;yelik verilerini, sitemizi kullanabilmen
          i&ccedil;in &uuml;ye olduğun s&uuml;rece saklayabiliriz. Ayrıca, talep
          etmen halinde derneğimizin faaliyetleri ile ilgili olarak sana ulaşmak
          i&ccedil;in de iletişim bilgilerini saklayabiliriz. Biraz daha
          a&ccedil;ık sıralamamız gerekirse:
        </p>
        <p>Dem Derneği faaliyetleri;</p>
        <ul>
          <li>
            Bukalemun &uuml;zerinden sağlanacak ve s&uuml;rekli olarak
            geliştirilecek i&ccedil;erikler ile ilgili her t&uuml;rl&uuml;
            &ccedil;evrimi&ccedil;i ve &ccedil;evrimdışı etkinlikler
            d&uuml;zenlemek; etkinlikleri d&uuml;zenlemek amacıyla
            &ccedil;ağrıda bulunmak,
          </li>
          <li>
            Bukalemun ile ilişkili olmaksızın Dem Derneği&rsquo;nin amacına
            uygun olarak gerektiğinde iştirakler ve paydaşlar ile
            &ccedil;evrimi&ccedil;i ve &ccedil;evrimdışı etkinlikler
            d&uuml;zenlemek; etkinlikleri d&uuml;zenlemek amacıyla
            &ccedil;ağrıda bulunmak,
          </li>
          <li>
            Dem Derneği&rsquo;nin amacına uygun farkındalık &ccedil;alışmaları
            yapmak, kamuoyunu bilgilendirmek ve bilgiyi doğrulamak,
          </li>
          <li>
            Dem Derneği&rsquo;nin amacına uygun olarak araştırmalar yapmak,
            derlemek ve anonim olarak kamuoyu ile paylaşmak,
          </li>
          <li>
            İzne tabii olarak yardım toplamak ve yardım toplamak i&ccedil;in
            &ccedil;ağrıda bulunmak,
          </li>
          <li>Burs vermek,</li>
          <li>
            Dem Derneği&rsquo;nin amacına uygun olarak gerektiğinde iştirakler
            ve paydaşlar ile birlikte sosyal sorumluluk ve sosyal girişimcilik
            projeleri geliştirmek, ger&ccedil;ekleştirmek ve uygulamak,
          </li>
        </ul>
        <p>
          i&ccedil;in kişisel verilerini işleyebiliriz, ama s&ouml;z veriyoruz
          ki, bu ama&ccedil;ların gerektirdiği sınırı aşmayacağız.
        </p>
        <p>
          <strong>3. Kişisel Verilerin Aktarımı</strong>
        </p>
        <p>
          KVKK&rsquo;nın 8. ve 9. maddeleri uyarınca kişisel verileriniz
          yukarıda sayılan ama&ccedil;lar dahilinde, Dem Derneği&rsquo;nin
          faaliyetlerinin s&uuml;rd&uuml;r&uuml;lebilmesi i&ccedil;in kişisel
          veri işleme şartları ve ama&ccedil;ları &ccedil;er&ccedil;evesinde
          gerekli g&ouml;r&uuml;len &uuml;&ccedil;&uuml;nc&uuml; kişilere
          (derneğimizin iktisadi işletmesi, iştirakleri, paydaşları, proje
          ortakları, işbirliği yapılan kurumlar, tedarik&ccedil;iler,
          bağış&ccedil;ılar, dernek y&ouml;neticileri, dernek
          &ccedil;alışanları, dernek genel kurulu, mali m&uuml;şavirler,
          danışmanlık hizmeti alınan kurumlar,hukuken yetkili kamu kurumu ve
          kuruluşları, hukuken yetkili &ouml;zel hukuk kişileri ya da KVKK
          şartlarına uygun olmak kaydıyla yurt dışı &uuml;lkeler)
          aktarılabilecektir.
        </p>
        <p>
          <strong>
            4. Kişisel Verilerin Toplanma Y&ouml;ntemi ve Hukuki Sebebi
          </strong>
        </p>
        <p>
          Kişisel verileriniz Dem Derneği tarafından yazılı, s&ouml;zl&uuml;
          veya elektronik kanallarla ve farklı hukuki sebeplere dayanarak Dem
          Derneği&rsquo;nin faaliyetlerini s&uuml;rd&uuml;r&uuml;lebilmesi
          i&ccedil;in KVKK tarafından &ouml;ng&ouml;r&uuml;len temel ilkelere
          uygun olarak, KVKK&rsquo;nın 5. ve 6. maddelerinde belirtilen kişisel
          veri işleme şartları ve ama&ccedil;ları kapsamında bu Aydınlatma
          Metninde belirtilen ama&ccedil;larla da toplanabilmekte,
          işlenebilmekte ve aktarılabilmektedir.
        </p>
        <p>
          Temel olarak verilerin toplanma y&ouml;ntemi, dernek faaliyetleri ve
          sitemize yaptığın &uuml;yelik başvurusudur.
        </p>
        <p>
          <strong>Kişisel Veri Sahibinin Hakları</strong>
        </p>
        <p>
          KVKK&rsquo;nın 11. maddesinde de d&uuml;zenlendiği &uuml;zere, bize
          başvurarak her zaman;
        </p>
        <p>a) Kişisel verilerinin işlenip işlenmediğini &ouml;ğrenme,</p>
        <p>b) Kişisel verilerin işlenmişse buna ilişkin bilgi talep etme,</p>
        <p>
          c) Kişisel verilerin işlenme amacını ve bunların amacına uygun
          kullanılıp kullanılmadığını &ouml;ğrenme,
        </p>
        <p>
          &ccedil;) Yurt i&ccedil;inde veya yurt dışında kişisel verilerin
          aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,
        </p>
        <p>
          d) Kişisel verilerin eksik veya yanlış işlenmiş olması h&acirc;linde
          bunların d&uuml;zeltilmesini isteme,
        </p>
        <p>
          e) KVKK 7. maddede &ouml;ng&ouml;r&uuml;len şartlar
          &ccedil;er&ccedil;evesinde kişisel verilerin silinmesini veya yok
          edilmesini isteme,
        </p>
        <p>
          f) (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin
          aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini
          isteme,
        </p>
        <p>
          g) İşlenen verilerin m&uuml;nhasıran otomatik sistemler vasıtasıyla
          analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
          &ccedil;ıkmasına itiraz etme,
        </p>
        <p>
          ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
          uğraması h&acirc;linde zararın giderilmesini talep etme
        </p>
        <p>haklarına sahipsin.</p>
        <p>
          Yukarıda belirtilen hakların ile ilgili Dem Derneği&rsquo;ne yazılı
          olarak (&ouml;rneğin e-mail ile info@demdernek.org&rsquo;a) başvurman
          halinde, talebinin niteliğine g&ouml;re en ge&ccedil; 30 (otuz)
          g&uuml;n i&ccedil;erisinde, ama elimizden gelen en kısa s&uuml;rede
          &uuml;cretsiz olarak yanıt vereceğiz.
        </p>
      </div>
    </div>
  );
};

export default kvkk;
