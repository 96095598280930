import React from 'react';
import { Link } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const Landing = () => {
  return (
    <div className='pt-50 pb-60'>
      <Container>
        <Row className='justify-ctr'>
          <Col sm={6} className='flex'>
            <Col xs={12} className='center-hr no-pad'>
              <div>
                <span className='txt-xxl txt-bold-mx txt-orng'>
                  <span className='txt-xxl txt-bold-mx'>Burası</span> bukalemun.
                </span>
              </div>
              <div className='mt-25'>
                <span className='txt-lg'>
                  Seni Türk İşaret Dili ile buluşturan yer.
                </span>
              </div>
              <div className='mt-80 mb-40'>
                <Link
                  to='/signUp'
                  className='btn txt-md btn-green btn-md pt-10'
                >
                  öğrenmek istiyorum
                </Link>
              </div>
            </Col>
          </Col>
          <Col sm={6}>
            <img
              src='https://storage.googleapis.com/bukalemun-bucket/images/landing-hand.png'
              alt='resim'
              width='100%'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Landing;
