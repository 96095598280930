import React, { useEffect, useState /*, useCallback */ } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../actions';
import { testWrapper as wrapper } from '../Helpers/wrappers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SidePanel = ({ tests, currentVideo, progress, getTest, history }) => {
  const [test, setTest] = useState(null);

  const currentTest = () => {
    let testLength = tests.length;
    let test;
    for (let x = 0; x < testLength; x++) {
      if (tests[x].courseOrder === currentVideo.order) {
        test = tests[x];
        break;
      }
    }
    if (!test) {
      test = { _id: '0000', title: 'çok yakında!' };
      return wrapper(test, false, false, () => {}, history);
    }

    return wrapper(
      test,
      progress.incompleteTestId === test._id,
      progress.testIds.includes(test._id),
      getTest,
      history
    );
  };

  useEffect(() => {
    if (!tests || !currentVideo || !progress) return;
    setTest(currentTest());
    //eslint-disable-next-line
  }, [tests, currentVideo]);

  return (
    <Row id='Watch-sidepanel'>
      <Col xl={9} md={10} sm={11} xs={12} className='no-pad pl-15'>
        <div className='txt-md txt-orng txt-bold-mx'>
          Bunlar da ilgini çekebilir
        </div>
      </Col>
      <Col xl={9} md={10} sm={11} xs={12} className='no-pad pl-15 mt-25 '>
        <div className='txt-sm'>Testler</div>
      </Col>
      <Col xl={9} md={10} sm={11} xs={12} className='no-pad'>
        {test}
      </Col>
      {/* <Col sm={6}>Kelimeler</Col> */}
    </Row>
  );
};

function mapStateToProps(data) {
  return {
    tests: data.testList,
    currentVideo: data.video,
    progress: data.progress
  };
}

export default connect(mapStateToProps, actions)(withRouter(SidePanel));
