import isAlpha from 'validator/lib/isAlpha';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isISO8601 from 'validator/lib/isISO8601';

/**
 * check string if email
 * @param string
 */
export function _isEmail(email) {
  if (isEmail(email)) return true;
  return false;
}

/**
 * checks if ASCII (22-127)
 * - must be 6-12 chars
 * @param string
 */
export function _isPassword(password) {
  const ascii = /^[\x22-\x7F]+$/g;
  if (!password.match(ascii)) return false;
  if (password.length < 6 || password.length > 12) return false;
  return true;
}

/**
 * checks if alphabetical
 * @param string
 */
export function _isName(name) {
  name.split(' ').forEach(val => {
    if (!isAlpha(val, 'tr-TR')) return false;
  });
  return true;
}

/**
 * checks if matches one of:
 * - +905xxxxxxxxx
 * - 05xxxxxxxxx
 * - 5xxxxxxxxx
 * @param string
 */
export function _isMobile(phone) {
  if (isMobilePhone(phone, 'tr-TR')) return true;
  return false;
}

/**
 * checks date & validity
 * @param string
 */
export function _isDate(date) {
  if (isISO8601(date, { strict: true })) return true;
  return false;
}

/**
 * checks if valid text
 * - with some special chars ( . , ; ! ? )
 * @param string
 */
export function _isText(text) {
  const rg = /[^a-zA-Z0-9.,;!?\sçÇğĞıİöÖşŞüÜ]/g;
  if (!text.match(rg)) return true;
  return false;
}

/**
 * checks if valid word
 * - without special chars
 * @param string
 */
export function _isWord(word) {
  if (isAlpha(word, 'tr-TR')) return true;
  return false;
}
