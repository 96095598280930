import axios from 'axios';

const domainBase =
	process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000';
const serverTimeout = 12500;

export const serverApi_noAuth = axios.create({
	baseURL: domainBase,
	timeout: serverTimeout
});

export const serverApi_authOnly = axios.create({
	baseURL: domainBase,
	timeout: serverTimeout
});
serverApi_authOnly.interceptors.request.use(function (config) {
	config.headers.Authorization = `Bearer ${window.sessionStorage.token}`;
	// console.log({ reqInterceptor: config.headers.Authorization }); // DELETE AFTER DEBUG
	return config;
});

export const serverApi_login = axios.create({
	baseURL: domainBase,
	timeout: serverTimeout
});
serverApi_login.interceptors.response.use(function (response) {
	sessionStorage.setItem('userId', response.data.userId);
	sessionStorage.setItem('name', response.data.name);
	sessionStorage.setItem('email', response.data.email);
	sessionStorage.setItem('token', response.data.token);
	// console.log({ resInterceptor: response.data.token }); // DELETE AFTER DEBUG
	return response;
});

export const serverApi_loginAdmin = axios.create({
	baseURL: domainBase,
	timeout: serverTimeout
});
serverApi_loginAdmin.interceptors.response.use(function (response) {
	sessionStorage.setItem('name', response.data.name);
	sessionStorage.setItem('token', response.data.token);
	//console.log({ resInterceptor: response.data }); // DELETE AFTER DEBUG
	return response;
});

export const serverApi_uploadAdmin = axios.create({
	baseURL: domainBase,
	timeout: 5 * 60 * 1000 // 5 mins
});
