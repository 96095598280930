import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

const nav_default = logoLink => (
  <Navbar collapseOnSelect expand='md' className='navbar-nav'>
    <div className='d-flex flex-nowrap'>
      <Navbar.Brand className='ml-n15 mt-n6'>
        <Nav.Link href={logoLink}>
          <img
            src='https://storage.googleapis.com/bukalemun-bucket/images/hdr-logo.svg'
            alt='Logo'
            className='btn btn-logo'
          />
        </Nav.Link>
      </Navbar.Brand>
      <Navbar.Toggle />
    </div>
    <Navbar.Collapse className='justify-content-end'>
      <Nav>
        <Nav.Link
          className='navlink btn btn-padded txt-md'
          rel='noopener noreferrer'
          href='https://fonzip.com/demdernek/bukalemunudestekliyorum?recurring=true'
          target='_blank'
        >
          destekle
        </Nav.Link>
        <Nav.Link href='/aboutUs' className='navlink btn btn-padded txt-md'>
          biz
        </Nav.Link>
        <Nav.Link
          href='/signUp'
          className='navlink btn btn-padded txt-md txt-bold'
        >
          kayıt ol
        </Nav.Link>
        <Nav.Link href='/login' className='navlink btn txt-md btn-green btn-sm'>
          giriş yap
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

const nav_loggedIn = (char, logoLink) => (
  <Navbar expand='sm' className='navbar-nav'>
    <div className='d-flex flex-nowrap justify-even'>
      <Navbar.Brand className='ml-n15'>
        <Nav.Link href={logoLink}>
          <img
            src='https://storage.googleapis.com/bukalemun-bucket/images/hdr-logo.svg'
            alt='Logo'
            className='btn btn-logo'
          />
        </Nav.Link>
      </Navbar.Brand>
      <Navbar.Toggle />
    </div>
    <Navbar.Collapse className='justify-content-end'>
      <Nav className='align-ctr'>
        <Nav.Link
          className='navlink btn btn-padded txt-md'
          rel='noopener noreferrer'
          href='https://fonzip.com/demdernek/bukalemunudestekliyorum?recurring=true'
          target='_blank'
        >
          destekle
        </Nav.Link>
        <Nav.Link href='/aboutUs' className='navlink btn btn-padded txt-md'>
          biz
        </Nav.Link>
        <Link
          to='/user/profile'
          className='navlink btn txt-xl btn-lg-round btn-green flex align-ctr justify-ctr'
        >
          {char}
        </Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

const Header = ({ data }) => {
  const [nav, setNav] = useState(nav_default);

  useEffect(() => {
    if (window.sessionStorage.token || data) {
      setNav(
        nav_loggedIn(
          window.sessionStorage.name[0].toLocaleUpperCase('TR'),
          '/user'
        )
      );
    } else {
      setNav(nav_default('/'));
    }
  }, [data]);

  return <Container style={{ padding: 0 }}>{nav}</Container>;
};
function mapStateToProps(data) {
  return { data: data.login };
}

export default connect(mapStateToProps)(Header);
