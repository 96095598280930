import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import { ScrollToTop, Loading } from './Helpers/helpers';
import CheckLogin from './Helpers/CheckLogin';
import Dialog from './Helpers/Dialog';

import Header from './Header';
import Landing from './Landing/Landing';
import Profile from './Profile/Profile';
import Footer from './Footer';
import { About } from './Info';
import News from './News';
import SignUp from './SignUp/SignUp';
import Login from './Login';
import User from './User/User';
import Watch from './Watch/Watch';
import Test from './Test/Test';
import List from './List/List';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ForgotPassword/ResetPassword';

import '../css/Components.css';
import '../css/alignments.css';
import '../css/backgrounds.css';
import '../css/bootstrp.css';
import '../css/buttons.css';
import '../css/mui.css';
import '../css/nodePackages.css';
import '../css/text.css';
import '../css/uncategorized.css';
import '../css/wrappers.css';

// admin routes
const LoadableAdminLogin = Loadable({
  loader: () => import('./Admin/Login'),
  loading: Loading
});
const LoadableAdmin = Loadable({
  loader: () => import('./Admin/Admin'),
  loading: Loading
});

const App = () => {
  return (
    <div>
      <Router>
        <Dialog />
        <ScrollToTop />
        <CheckLogin />
        <div>
          <Header />

          <Route exact path='/' component={Landing} />

          {/***** no-auth *****/}
          <Route path='/aboutUs' component={About} />
          <Route path='/news' component={News} />
          <Route path='/signUp' component={SignUp} />
          <Route path='/login' component={Login} />
          <Route path='/forgotPassword' component={ForgotPassword} />
          <Route path='/resetPassword' component={ResetPassword} />

          {/***** user *****/}
          <Route exact path='/user' component={Profile} />
          <Route exact path='/user/profile' component={User} />
          <Route path='/user/watch' component={Watch} />
          <Route path='/user/test' component={Test} />
          <Route path='/user/list/:contentType' component={List} />

          {/***** admin *****/}
          <Route exact path='/dem/login' component={LoadableAdminLogin} />
          <Route exact path='/dem' component={LoadableAdmin} />

          <Footer />
        </div>
      </Router>
    </div>
  );
};

export default App;
