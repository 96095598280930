import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import Redirect2Page from './Helpers/Redirect2Page';
import * as actions from '../actions';
import Results from './List/Results';

const News = ({ data, getNews }) => {
  const memoizedNews = useCallback(() => getNews(), [getNews]);
  useEffect(() => {
    if (!data) memoizedNews();
  }, [data, memoizedNews]);

  return (
    <div>
      <div className='mt-25 mb-n30'>
        <Redirect2Page page='/' />
      </div>
      <Container>
        <Row className='txt-ctr mr-0 ml-0'>
          <Col xs={12} className='mt-60'>
            <span className='txt-green txt-bold-mx txt-xxl'>yeni</span>
          </Col>
          <Col xs={12} className='mb-25'>
            <span className='txt-lt-green txt-lt txt-xxl'>neler var?</span>
          </Col>
          <Col xs={12} className='no-pad'>
            <Results contentType='news' results={data} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

function mapStateToProps(data) {
  return { data: data.news };
}

export default connect(mapStateToProps, actions)(News);
