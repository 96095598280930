import React from 'react';

import Row from 'react-bootstrap/Row';

import { alphList } from '../../data/lists';
import Carousel from './Carousel';
import useTimeout from '../../hooks/useTimeout';

const Letters = ({ handleClick }) => {
  const timedOut = useTimeout(2500);
  const carouselStyle = !timedOut ? { visibility: 'hidden' } : {};

  const items = alphList.map(char => (
    <div
      className='btn-green btn-sm-round flex align-ctr justify-ctr txt-lg'
      onClick={() => handleClick(char)}
      key={char}
    >
      {char}
    </div>
  ));

  return (
    <Row className='ml-0 mr-0 mt-10 no-pad' style={carouselStyle}>
      <Carousel items={items} count={[7, 10, 14, 18]} addTransform={2} />
    </Row>
  );
};

export default Letters;
