import { useEffect, useState } from 'react';

import { currentItems } from '../components/Helpers/helpers';

export default function useCurrentPageItems(
  contentType,
  data,
  wrapper,
  progress = null,
  count = 12,
  page = 1
) {
  const [renderList, setRenderList] = useState(null);
  const noProgress =
    !progress && (contentType === 'videos' || contentType === 'tests');

  useEffect(() => {
    if (!data || noProgress) return;
    let items2Render = currentItems(data, page, count);
    setRenderList(items2Render.map(wrapper));
    // eslint-disable-next-line
  }, [page, data]);

  return renderList;
}
