import React from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { _isText, _isWord } from '../Helpers/Validators';
import {
  reformatName,
  reformatSurname,
  reformatText,
  reformatWord
} from '../Helpers/Reformatters';
import {
  SelectOneComponent,
  TextComponent,
  CheckboxComponent,
  ConfirmationDialog
} from '../Helpers/FieldComponents';
import * as actions from '../../actions';

const reformatValues = values => {
  const surveyAnswers = [];
  const fields = ['q1', 'q2', 'q3', 'q4'];
  fields.forEach(field => surveyAnswers.push(values[field]));
  surveyAnswers.push(reformatWord(values.q5));
  surveyAnswers.push(values.q6 ? reformatText(values.q6) : '');

  return {
    ...values,
    name: reformatName(values.name),
    surname: reformatSurname(values.surname),
    type: values.isStudent ? 'Student' : 'Teacher',
    surveyAnswers: surveyAnswers
  };
};

const validate = values => {
  const errors = {};

  if (!values.q4) errors.q4 = 'Zorunlu alan.';
  if (!values.kvkk) errors.kvkk = 'Zorunlu alan.';

  if (!values.q5) {
    errors.q5 = 'Zorunlu alan.';
  } else if (!_isWord(values.q5)) errors.q5 = 'Geçersiz karakter var.';

  if (values.q6 && !_isText(values.q6)) errors.q6 = 'Geçersiz karakter var.';

  return errors;
};

let Form3 = ({
  user_signUp,
  history,
  survey,
  formValues,
  handleSubmit,
  handlePrevious,
  invalid,
  submitting,
  pristine
}) => {
  const submitForm = () => {
    const formattedVals = reformatValues(formValues.values);
    handleSubmit(user_signUp(formattedVals, history));
  };

  return (
    <form onSubmit={handleSubmit(() => submitForm())}>
      <Row className='align-l mt-25'>
        <Col sm={12} className='mt-40 mb-25'>
          <span className='txt-lg txt-green txt-bold-mx'>kişisel bilgiler</span>
        </Col>
        <Col xs={1}>
          <div className='back-orng circle-25 txt-white txt-ctr p-t1 p-l1'>
            4
          </div>
        </Col>
        <Col lg={11} xs={10}>
          <div className='txt-sm'>{survey.single[0].question}</div>
        </Col>
        <Col lg={{ span: 11, offset: 1 }} xs={12} className='mb-25'>
          <Field
            name='q4'
            list={survey.single[0].choices}
            component={SelectOneComponent}
            placeholder='lütfen cevabını seç.'
          />
        </Col>
        <Col xs={1}>
          <div className='back-orng circle-25 txt-white txt-ctr p-t1 p-l1'>
            5
          </div>
        </Col>
        <Col lg={11} xs={10}>
          <div className='txt-sm'>{survey.text[0].question}</div>
        </Col>
        <Col lg={{ span: 11, offset: 1 }} xs={12} className='mb-25'>
          <Field
            name='q5'
            component={TextComponent}
            placeholder='lütfen cevabını yaz.'
          />
        </Col>
        <Col xs={1}>
          <div className='back-orng circle-25 txt-white txt-ctr p-t1 p-l1'>
            6
          </div>
        </Col>
        <Col lg={11} xs={10}>
          <div className='txt-sm'>{survey.text[1].question}</div>
        </Col>
        <Col lg={{ span: 11, offset: 1 }} xs={12} className='mb-15'>
          <Field
            name='q6'
            component={TextComponent}
            placeholder='lütfen eklemek istediklerini yaz.'
            mLine={true}
            rows={5}
          />
        </Col>
        <Col lg={{ span: 11, offset: 1 }} xs={12}>
          <Field
            name='kvkk'
            label='KVKK Aydınlatma Metnini okudum, kabul ediyorum.'
            component={ConfirmationDialog}
          />
        </Col>
        <Col lg={{ span: 11, offset: 1 }} xs={12} className='mb-25'>
          <Field
            name='inMailingList'
            label="Bukalemun'dan haber almak istiyorum."
            component={CheckboxComponent}
            checked={true}
          />
        </Col>
        <Col lg={{ span: 11, offset: 1 }} xs={12}>
          <Row className='mb-60 wrap-rev'>
            <Col lg={6} xs={12} className='mt-25'>
              <button
                onClick={() => handlePrevious()}
                className='btn-gray btn-full'
              >
                geri dön
              </button>
            </Col>
            <Col lg={6} xs={12} className='mt-25'>
              <button
                type='submit'
                disabled={pristine || submitting || invalid}
                className='btn-green btn-full'
              >
                kaydı tamamla
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

Form3 = reduxForm({
  form: 'signUp',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(Form3);

function mapStateToProps(state) {
  return { survey: state.survey, formValues: state.form.signUp };
}

export default connect(mapStateToProps, actions)(withRouter(Form3));
