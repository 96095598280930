import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import ReactPlayer from 'react-player';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Radio from './Radio';

const validate = values => {
  const errors = {};
  if (!values.answerId)
    errors.answerId = 'ilerleyebilmen için bir seçim yapman lazım.';
  return errors;
};

let Question = ({
  qIndex,
  count,
  question,
  handleSubmit,
  post,
  formValue,
  invalid,
  pristine,
  submitting
}) => {
  if (!question) return null;
  const isLast = qIndex === count;

  return (
    <Row>
      <form
        onSubmit={handleSubmit(() => post(formValue.values))}
        className='w-100'
      >
        <Col lg={10} xs={11} className='center-hr'>
          <div className='txt-lg txt-drk-orng border-b1-orng p-b5'>
            SORU{'  '}
            {qIndex}
            <span className='txt-md'> / {count}</span>
          </div>
        </Col>
        <Col lg={10} xs={11} className='txt-l mb-50 mt-40 center-hr noselect'>
          {question.videoId && (
            <div className='mb-25'>
              <ReactPlayer
                url={question.videoId.url}
                width={'100%'}
                controls={true}
              />
            </div>
          )}
          {question.text}
        </Col>
        <Col lg={10} xs={11} className='txt-l center-hr'>
          <Field list={question.answerIds} component={Radio} name='answerId' />
        </Col>
        <Col xs={12} className='txt-r mt-100 mb-n150'>
          <button
            type='submit'
            disabled={pristine || submitting || invalid}
            className='btn-green btn-md-sh'
          >
            {isLast ? 'kaydet ve bitir' : 'ilerle'}
          </button>
        </Col>
      </form>
    </Row>
  );
};

Question = reduxForm({
  form: 'qForm',
  destroyOnUnmount: true,
  validate
})(Question);

function mapStateToProps(state) {
  return { formValue: state.form.qForm };
}

export default connect(mapStateToProps)(Question);
