import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { renderFlag, testWrapper } from '../../../Helpers/wrappers';
import useCurrentPageItems from '../../../../hooks/useCurrentPageItems';
import usePageNav from '../../../../hooks/usePageNav';
import * as actions from '../../../../actions';

const PastTests = ({ filteredTests, progress, getTest, history }) => {
  const avg = progress ? progress.avgScore : 0;
  const count = progress ? progress.testCount : 0;
  const txtClass1 = 'txt-green txt-sm txt-bold txt-no-wrap';
  const txtClass2 = 'txt-lt-gray txt-sm txt-lt txt-no-wrap';
  const avgFlag = renderFlag('score', avg, txtClass1, txtClass2);
  const countFlag = renderFlag('test', count, txtClass1, txtClass2);

  const tstWrapper = test => {
    let isIncomplete = progress.incompleteTestId === test._id;
    let isCompleted = progress.testIds.includes(test._id);
    return (
      <Col xl={4} md={6} xs={12} key={test._id}>
        {testWrapper(
          test,
          isIncomplete,
          isCompleted,
          getTest,
          history,
          progress.results
        )}
      </Col>
    );
  };

  const displayCount = 12;
  const { page, navButtons } = usePageNav(filteredTests, displayCount);
  const renderList = useCurrentPageItems(
    'tests',
    filteredTests,
    tstWrapper,
    progress,
    displayCount,
    page
  );

  return (
    <Row className='mb-25 justify-ctr'>
      <Col xs={12} className='mb-n60 z-2 no-pad'>
        <Row className='justify-ctr'>
          <Col xl={4} lg={5} md={6} sm={6} xs={10}>
            {avgFlag ? avgFlag : null}
          </Col>
          <Col xl={4} lg={5} md={6} sm={6} xs={10}>
            {countFlag ? countFlag : null}
          </Col>
        </Row>
      </Col>
      <Col xs={12} className='paper back-lt-green no-pad pt-50 pb-40 mb-40'>
        <Row className='pt-50'>{renderList}</Row>
      </Col>
      <Col xs={12} className='txt-r'>
        {navButtons}
      </Col>
    </Row>
  );
};

function mapStateToProps(data) {
  return { progress: data.progress };
}

export default connect(mapStateToProps, actions)(withRouter(PastTests));
