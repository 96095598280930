import { useState, useEffect } from 'react';

export default function useTimeout(ms) {
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setTimedOut(true), ms);
    return () => clearTimeout(timer);
  }, [ms]);

  return timedOut;
}
