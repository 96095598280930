import React from 'react';
import { reduxForm, Field } from 'redux-form';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {
  _isName,
  _isEmail,
  _isMobile,
  _isDate,
  _isPassword
} from '../Helpers/Validators';
import { cityList } from '../../data/lists';
import {
  StudentCheckbox,
  SelectOneComponent,
  TextComponent,
  PasswordComponent,
  DateComponent
} from '../Helpers/FieldComponents';

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Zorunlu alan.';
  } else if (!_isName(values.name)) errors.name = 'Geçersiz karakter var.';

  if (!values.surname) {
    errors.surname = 'Zorunlu alan.';
  } else if (!_isName(values.surname))
    errors.surname = 'Geçersiz karakter var.';

  if (!values.email) {
    errors.email = 'Zorunlu alan.';
  } else if (!_isEmail(values.email)) errors.email = 'E-posta formatı hatalı.';

  if (!values.phone) {
    errors.phone = 'Zorunlu alan.';
  } else if (!_isMobile(values.phone))
    errors.phone = '05xxxxxxxxx formatında olmalı.';

  if (!values.birthdate) {
    errors.birthdate = 'Zorunlu alan';
  } else if (!_isDate(values.birthdate))
    errors.birthdate = 'Yıl/Ay/Gün formatında olmalı.';

  if (!values.currentCity) errors.currentCity = 'Zorunlu alan.';

  if (!values.password) {
    errors.password = 'Zorunlu alan.';
  } else if (!_isPassword(values.password)) {
    errors.password = 'Şifre en az 6 en fazla 12 karakterden oluşmalı.';
  }
  if (!values.passwordCheck) {
    errors.passwordCheck = 'Zorunlu alan.';
  } else if (values.password !== values.passwordCheck) {
    errors.passwordCheck = 'Şifre ve şifre tekrarı aynı olmalı.';
  }

  return errors;
};

let Form1 = ({ handleSubmit, invalid, submitting, pristine }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Row className='align-l mt-25'>
        <Col lg={6} xs={12} className='mt-10'>
          <Field
            name='isStudent'
            component={StudentCheckbox}
            checked={true}
            disabled={true}
          />
        </Col>
        <Col sm={12} className='mt-25'>
          <span className='txt-lg txt-green txt-bold-mx'>kişisel bilgiler</span>
        </Col>
        <Col lg={6} className='mt-10'>
          <Field name='name' label='adın' component={TextComponent} />
        </Col>
        <Col lg={6} className='mt-10'>
          <Field name='surname' label='soyadın' component={TextComponent} />
        </Col>
        <Col sm={12} className='mt-10'>
          <Field
            name='email'
            label='e-posta adresin'
            component={TextComponent}
          />
        </Col>
        <Col sm={12} className='mt-10'>
          <Field
            name='phone'
            label='telefon numaran'
            component={TextComponent}
            placeholder='05xxxxxxxxx'
          />
        </Col>
        <Col lg={6} className='mt-10'>
          <Field
            name='birthdate'
            label='doğum tarihin'
            component={DateComponent}
          />
        </Col>
        <Col lg={6} className='mt-10'>
          <Field
            name='currentCity'
            label='yaşadığın şehir'
            component={SelectOneComponent}
            list={cityList}
          />
        </Col>
        <Col lg={6} className='mt-10'>
          <Field name='password' label='şifre' component={PasswordComponent} />
        </Col>
        <Col lg={6} className='mt-10'>
          <Field
            name='passwordCheck'
            label='şifre tekrar'
            component={PasswordComponent}
          />
        </Col>
        <Col sm={12} className='mt-40 mb-15'>
          <button
            type='submit'
            disabled={pristine || submitting || invalid}
            className='btn-green btn-full'
          >
            ilerle
          </button>
        </Col>
      </Row>
    </form>
  );
};

export default reduxForm({
  form: 'signUp',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(Form1);
