import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import '../../css/text.css';
import '../../css/buttons.css';

const useStyles = makeStyles({
  paper: {
    borderRadius: 15
  }
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  text: {
    marginRight: 50,
    marginLeft: 10,
    marginTop: 10,
    marginBottom: -20
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.text}>
        <div className='txt-bold'>
          <p>{children}</p>
        </div>
      </div>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    justifyContent: 'flex-start'
  }
}))(MuiDialogActions);

const WindowDialog = ({ dialog, resetDialog, history }) => {
  const classes = useStyles();
  const [message, setMessage] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const [open, setOpen] = useState(false);

  const resetState = () => {
    setMessage(null);
    setRedirectUrl(null);
    resetDialog();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    setOpen(false);
    if (redirectUrl) history.push(redirectUrl);
  };

  useEffect(() => {
    if (!dialog) return;
    setMessage(dialog.message);
    setRedirectUrl(dialog.redirectUrl ? dialog.redirectUrl : null);
    setOpen(true);
  }, [dialog]);

  return (
    <div>
      <Dialog
        classes={{ paper: classes.paper }}
        onClose={handleCancel}
        open={open}
        onExited={resetState}
      >
        <DialogTitle onClose={handleCancel}>{message}</DialogTitle>
        <DialogActions>
          {redirectUrl && (
            <button className='btn-gray btn-sm' onClick={handleCancel}>
              iptal
            </button>
          )}
          <button
            className='btn-orange btn-sm no-border'
            onClick={handleAccept}
          >
            tamam
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function mapStateToProps(data) {
  return { dialog: data.dialog };
}

export default connect(mapStateToProps, actions)(withRouter(WindowDialog));
