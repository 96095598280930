import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions';
import { _isPassword } from '../Helpers/Validators';
import { PasswordComponent } from '../Helpers/FieldComponents';
import Redirect2Page from '../Helpers/Redirect2Page';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const validate = values => {
  const errors = {};
  if (!values.newPassword) {
    errors.newPassword = 'Zorunlu alan.';
  } else if (!_isPassword(values.newPassword)) {
    errors.newPassword = 'Şifre en az 6 en fazla 12 karakterden oluşmalı.';
  }
  if (!values.passwordCheck) {
    errors.passwordCheck = 'Zorunlu alan.';
  } else if (values.newPassword !== values.passwordCheck) {
    errors.passwordCheck = 'Şifre ve şifre tekrarı aynı olmalı.';
  }
  return errors;
};

let ResetPassword = ({
  history,
  handleSubmit,
  user_resetPassword,
  formValues,
  invalid,
  submitting,
  pristine
}) => {
  const token = window.location.href.split('=')[1];

  const onSubmit = () => {
    if (!token) return;
    user_resetPassword(
      {
        newPassword: formValues.values.newPassword,
        token: token
      },
      history
    );
  };

  return (
    <div>
      <div className='mt-25 mb-n30'>
        <Redirect2Page page='/' />
      </div>
      <Container>
        <Row className='align-ctr justify-ctr'>
          <Col sm={6} xs={11}>
            <Row className='align-ctr p-30 mt-40'>
              <Col lg={11} xs={12} className='mt-n10'>
                <img
                  src='https://storage.googleapis.com/bukalemun-bucket/images/forgot.jpg'
                  alt='resim'
                  width='100%'
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6} xs={12} className='mb-60'>
            <form onSubmit={handleSubmit(() => onSubmit())}>
              <Row className='paper-lg mt-0 ml-0 mr-0 pt-80 pb-100 justify-ctr'>
                <Col lg={10} xs={11}>
                  <div className='txt-lg-2 txt-bold txt-ctr'>
                    şifre yenileme
                  </div>
                </Col>
                <Col lg={10} xs={11} className='mt-40'>
                  <Field
                    label='yeni şifre'
                    name='newPassword'
                    component={PasswordComponent}
                  />
                </Col>
                <Col lg={10} xs={11} className='mt-25'>
                  <Field
                    name='passwordCheck'
                    label='yeni şifre (tekrar)'
                    component={PasswordComponent}
                  />
                </Col>
                <Col lg={10} xs={11} className='mt-25'>
                  <button
                    type='submit'
                    disabled={invalid || submitting || pristine}
                    className='btn-green btn-full'
                  >
                    şifremi değiştir
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ResetPassword = reduxForm({
  form: 'resetPassword',
  validate
})(ResetPassword);

function mapStateToProps(state) {
  return { formValues: state.form.resetPassword };
}

export default connect(mapStateToProps, actions)(withRouter(ResetPassword));
