import React from 'react';

import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftSharp';

const PageButtons = ({ currentPage, length, displayCount, handlePage }) => {
  const style = {
    color: '#161211',
    fontSize: '40',
    verticalAlign: 'super'
  };
  const next = (
    <button onClick={() => handlePage(1)} className='btn btn-underlined no-pad'>
      <ChevronRightIcon style={style} />
    </button>
  );
  const prev = (
    <button
      onClick={() => handlePage(-1)}
      className='btn btn-underlined no-pad'
    >
      <ChevronLeftIcon style={style} />
    </button>
  );

  const pageCount = length === 0 ? 1 : Math.ceil(length / displayCount);
  const hasMorePages = length > currentPage * displayCount;

  let nextBtn, prevBtn;
  if (currentPage > 1 && hasMorePages) {
    // next & prev
    nextBtn = next;
    prevBtn = prev;
  } else if (currentPage > 1) {
    // prev
    prevBtn = prev;
    nextBtn = null;
  } else if (hasMorePages) {
    // next
    nextBtn = next;
    prevBtn = null;
  } else {
    nextBtn = null;
    prevBtn = null;
  }

  return (
    <div>
      {prevBtn}
      &nbsp;
      <span className='txt-xl txt-bold'>{currentPage} /</span>
      &nbsp;<span className='txt-md txt-lt-gray'>{pageCount}</span>&nbsp;
      {nextBtn}
    </div>
  );
};

export default PageButtons;
