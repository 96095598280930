import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { _isName, _isMobile } from '../../../Helpers/Validators';
import { reformatName, reformatSurname } from '../../../Helpers/Reformatters';
import { cityList } from '../../../../data/lists';
import {
  TextComponent,
  SelectOneComponent
} from '../../../Helpers/FieldComponents';
import * as actions from '../../../../actions';

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  } else if (!_isName(values.name)) errors.name = 'Invalid characters';

  if (!values.surname) {
    errors.surname = 'Required';
  } else if (!_isName(values.surname)) errors.surname = 'Invalid characters';

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!_isMobile(values.phone.toString()))
    errors.phone = 'Invalid number';

  if (!values.currentCity) errors.currentCity = 'Required';

  return errors;
};

const reformatValues = values => {
  return {
    ...values,
    name: reformatName(values.name),
    surname: reformatSurname(values.surname)
  };
};

let UpdateForm = ({
  initialValues,
  handleSubmit,
  updateUser,
  formValues,
  history,
  invalid,
  submitting,
  pristine
}) => {
  useEffect(() => {
    if (!initialValues) return;
  }, [initialValues]);

  return (
    <form
      onSubmit={handleSubmit(() =>
        updateUser(reformatValues(formValues.values), history)
      )}
    >
      <Row className='mb-40'>
        <Col md={6} xs={12} className='mt-25'>
          <Field key='name' name='name' component={TextComponent} label='ad' />
        </Col>
        <Col md={6} xs={12} className='mt-25'>
          <Field
            key='surname'
            name='surname'
            component={TextComponent}
            label='soyad'
          />
        </Col>
        <Col md={6} xs={12} className='mt-25'>
          <Field
            key='phone'
            name='phone'
            component={TextComponent}
            label='telefon numarası'
          />
        </Col>
        <Col md={6} xs={12} className='mt-25'>
          <Field
            name='currentCity'
            label='şehir'
            component={SelectOneComponent}
            list={cityList}
          />
        </Col>
        <Col md={6} xs={12} className='mt-40'>
          <div>
            <button
              className='btn-green btn-full txt-md txt-ctr'
              type='submit'
              disabled={invalid || submitting || pristine}
            >
              bilgilerimi güncelle
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

UpdateForm = reduxForm({
  form: 'updateForm',
  validate
})(UpdateForm);

function mapStateToProps(state) {
  return {
    formValues: state.form.updateForm,
    initialValues: state.userData.user
  };
}

export default connect(mapStateToProps, actions)(withRouter(UpdateForm));
