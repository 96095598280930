import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 9px 0 rgba(21, 27, 38, 0.1)',
    border: 'solid 1px #d4d7dd'
  }
}));

/**
 * @param-<type> must be 'video' or 'image'
 */
const TransitionsModal = ({ src, thumbSrc, type, divClass }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  let thumb, content;
  if (type === 'image') {
    thumb = (
      <img
        src={thumbSrc}
        alt='yeni'
        width='100%'
        className=''
        scrolling='no'
        seamless
      />
    );
    content = <img src={src} alt='yeni' width='100%' scrolling='no' seamless />;
  }
  if (type === 'video') {
    thumb = (
      <img
        src={thumbSrc}
        alt='yeni'
        width='100%'
        className=''
        scrolling='no'
        seamless
      />
    );
    content = <ReactPlayer url={src} width='100%' controls={true} />;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={divClass}>
      <span onClick={handleOpen} className='btn no-pad'>
        {thumb}
      </span>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>{content}</div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
