import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const Footer = () => {
  return (
    <div>
      <div className='back-lt-green footer-top'>
        <Container style={{ padding: '0' }}>
          <Row className='justify-even'>
            <Col xl={9} sm={8} xs={12}>
              <Row className='justify-even'>
                <Col
                  xl='auto'
                  md={12}
                  sm={11}
                  xs={10}
                  className='align-l pt-15'
                >
                  <Row className='no-wrap'>
                    <Col xs={1}>
                      <img
                        src='https://storage.googleapis.com/bukalemun-bucket/images/email.png'
                        alt='mail'
                        className='align-ttop mr-10'
                      />
                    </Col>
                    <Col xs={11}>
                      <a
                        className='btn txt-sm txt-drk-green align-l'
                        href='mailto:info@demdernek.org'
                      >
                        info@demdernek.org
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl='auto'
                  md={12}
                  sm={11}
                  xs={10}
                  className='align-l pt-15'
                >
                  <Row className='no-wrap'>
                    <Col xs={1}>
                      <img
                        src='https://storage.googleapis.com/bukalemun-bucket/images/phone.png'
                        alt='phone'
                        className='align-ttop mr-10'
                      />
                    </Col>
                    <Col xs={11}>
                      <a
                        className='btn txt-sm txt-drk-green align-l'
                        href='tel:+90-530-136-0217'
                      >
                        +90 530 136 02 17
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl='auto'
                  md={12}
                  sm={11}
                  xs={10}
                  className='align-l pt-15'
                >
                  <Row className='no-wrap'>
                    <Col xs={1}>
                      <img
                        src='https://storage.googleapis.com/bukalemun-bucket/images/placeholder.png'
                        alt='phone'
                        className='align-ttop mr-10'
                      />
                    </Col>
                    <Col xs={11}>
                      <a
                        className='btn align-l'
                        href='https://goo.gl/maps/dEeGizZDBQUcBSqbA'
                      >
                        <span className=' txt-sm txt-drk-green align-l txt-wrap'>
                          Tomtom Mah. Yeniçarşı Cad. No:68 D:21
                          <br />
                          Beyoğlu / İstanbul
                        </span>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col sm={3} xs={12} className='align-l pt-15'>
              <Row id='Footer-row'>
                <Col sm={12}>
                  <a href='https://www.youtube.com/channel/UCrMMftwP5dPEM9JliVXzRlQ/featured'>
                    <img
                      src='https://storage.googleapis.com/bukalemun-bucket/images/youtube.png'
                      alt='youtube'
                      className='mr-10 align-ttop'
                    />
                  </a>
                  <a href='https://www.linkedin.com/company/dem-dernek'>
                    <img
                      src='https://storage.googleapis.com/bukalemun-bucket/images/linkedin.png'
                      alt='linkedin'
                      className='mr-10 align-ttop'
                    />
                  </a>
                  <a href='https://www.instagram.com/demdernegi/'>
                    <img
                      src='https://storage.googleapis.com/bukalemun-bucket/images/instagram.png'
                      alt='instagram'
                      className='mr-10 align-ttop'
                    />
                  </a>
                </Col>
                <Col sm={12}>
                  <div id='Footer-div' className='txt-xs txt-orng'>
                    Bukalemun bir Dem Derneği sosyal girişimidir.
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='back-green footer-bottom'>
        <Container>
          <Row>
            <Col sm={12}>
              <div className='txt-gray txt-sm txt-ctr '>© 2020 Dem Derneği</div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
