import React from 'react';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeftSharp';

const style = {
  fontSize: '30',
  color: '#a0a0a0',
  marginLeft: '-10px',
  marginRight: '-25px'
};

const prevIcon = <ChevronLeftIcon aria-hidden='true' style={style} />;

const Redirect2Page = ({
  history,
  page = '/user',
  text = 'ana sayfaya dön'
}) => {
  return (
    <Container>
      <button onClick={() => history.push(page)} className='btn no-pad'>
        <Row className='no-wrap align-ctr'>
          <Col xs={1}>{prevIcon}</Col>
          <Col xs={11}>
            <span className='txt-lt-gray txt-sm ml-n15 txt-underlined'>
              {text}
            </span>
          </Col>
        </Row>
      </button>
    </Container>
  );
};

export default withRouter(Redirect2Page);
