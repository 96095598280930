import React, { useState } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

const ImageSkeleton = ({ skHeight, skWidth, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const handleImageLoad = () => setLoaded(true);
  const imgStyle = !loaded ? { display: 'none' } : {};

  return (
    <>
      {!loaded && (
        <Skeleton
          variant='rect'
          animation='wave'
          width={skWidth}
          height={skHeight}
        />
      )}
      <img {...rest} alt={rest.alt} style={imgStyle} onLoad={handleImageLoad} />
    </>
  );
};

export default ImageSkeleton;
