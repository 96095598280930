import {
  LOGIN,
  PROGRESS,
  VIDEOLIST,
  TESTLIST,
  USERDATA,
  WORDLIST,
  VIDEO,
  TEST,
  QUESTION,
  RESULT,
  LOGOUT,
  UPDATE_USER,
  LOGIN_A,
  POST_A,
  GET_A,
  SURVEY,
  NEWS,
  SCORE,
  DIALOG
} from './types';
import {
  serverApi_login,
  serverApi_authOnly,
  serverApi_loginAdmin,
  serverApi_noAuth,
  serverApi_uploadAdmin
} from '../apis/calls';

const dialogPayload = (err, url = null) => {
  return {
    message: err.response && err.response.data ? err.response.data.message : '',
    redirectUrl: url
  };
};

export const resetDialog = () => dispatch => {
  dispatch({ type: DIALOG, payload: null });
};

export const getNews = () => async dispatch => {
  let res;
  try {
    res = await serverApi_noAuth.get('/api/news/list');
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: NEWS, payload: res.data });
};

export const user_signUp = (values, history) => async dispatch => {
  let res;
  try {
    res = await serverApi_login.post('/api/users/signup', values);
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err, '/signUp') });
    return;
  }

  dispatch({ type: LOGIN, payload: res.data });
  dispatch({
    type: DIALOG,
    payload: { message: res.data.message, redirectUrl: null }
  });
  history.push('/user');
};

export const getSurveyQuestions = () => async dispatch => {
  let res;
  try {
    res = await serverApi_noAuth.get('/api/users/surveyQuestions');
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: SURVEY, payload: res.data });
};

export const user_login = (values, history) => async dispatch => {
  let res;
  try {
    res = await serverApi_login.post('/api/users/login', values);
  } catch (err) {
    let redirectUrl = null;
    if (err.response && err.response.status === 403)
      redirectUrl = '/forgotPassword';
    dispatch({ type: DIALOG, payload: dialogPayload(err, redirectUrl) });
    return;
  }

  dispatch({ type: LOGIN, payload: res.data });
  history.push('/user');
};

export const user_resetPassword = (values, history) => async dispatch => {
  let res;
  try {
    res = await serverApi_login.post(
      '/api/users/forgot_password/reset',
      values
    );
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: LOGIN, payload: res.data });
  history.push('/user');
};

export const getResetLink = (values, history) => async dispatch => {
  try {
    await serverApi_noAuth.post('/api/users/forgot_password', values);
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }
  dispatch({
    type: DIALOG,
    payload: {
      message: 'E-posta adresine şifre yenileme linkini gönderdik.'
    }
  });

  history.push('/');
};

export const getProfile = () => async dispatch => {
  let progress, videos, tests;
  try {
    progress = await serverApi_authOnly.get('/api/profile/progress');
    videos = await serverApi_authOnly.get('/api/videos/list/course');
    tests = await serverApi_authOnly.get('/api/tests/list');

    dispatch({ type: PROGRESS, payload: progress.data });
    dispatch({ type: VIDEOLIST, payload: videos.data });
    dispatch({ type: TESTLIST, payload: tests.data });
  } catch (err) {
    if (err.response && err.response.status === 403) {
      dispatch({
        type: DIALOG,
        payload: { message: 'Önce giriş yapman gerekli' }
      });
    } else {
      dispatch({ type: DIALOG, payload: dialogPayload(err) });
    }
    sessionStorage.clear();
    dispatch({ type: LOGOUT });
    window.location.reload();
    return;
  }
};

export const user_progress = () => async dispatch => {
  let res;
  try {
    res = await serverApi_authOnly.get('/api/profile/progress');
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: PROGRESS, payload: res.data });
};

// returns course videos
export const getVideos = () => async dispatch => {
  let res;
  try {
    res = await serverApi_authOnly.get('/api/videos/list/course');
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: VIDEOLIST, payload: res.data });
};

export const getTests = () => async dispatch => {
  let res;
  try {
    res = await serverApi_authOnly.get('/api/tests/list');
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: TESTLIST, payload: res.data });
};

// returns 12 (at most) at a time
export const getWordsByChar = char => async dispatch => {
  let res;
  try {
    res = await serverApi_authOnly.get(`/api/words/list/${char}`);
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: WORDLIST, payload: res.data });
};

export const getUserData = () => async dispatch => {
  let res;
  try {
    res = await serverApi_authOnly.get('/api/profile/user');
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: USERDATA, payload: res.data });
};

export const getVideo = (videoId, history) => async dispatch => {
  let res;
  try {
    res = await serverApi_authOnly.post('/api/videos/watch', {
      videoId: videoId
    });
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: VIDEO, payload: res.data });
  history.push('/user/watch');
};

export const postWatched = video => async dispatch => {
  try {
    await serverApi_authOnly.post('/api/videos/watch/ended', {
      videoId: video._id
    });
    console.log('reqSent');
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }
};

export const getTest = (testId, history) => async dispatch => {
  let res;
  try {
    res = await serverApi_authOnly.post('/api/tests/test/begin', {
      testId: testId
    });
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  if (res.data.code === 403) {
    dispatch({
      type: DIALOG,
      payload: {
        message: 'Yeni bir teste başlayabilmen için son testini bitirmen gerek.'
      }
    });
    return;
  }

  dispatch({ type: TEST, payload: res.data });
  dispatch({
    type: DIALOG,
    payload: { message: res.data.test.info, redirectUrl: '/user/test' }
  });
};

export const getQuestion = testId => async dispatch => {
  let res;
  try {
    res = await serverApi_authOnly.post('/api/tests/test/question', { testId });
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: QUESTION, payload: res.data });
};

export const postAnswer = (
  answer,
  qIndex,
  testId,
  isLast
) => async dispatch => {
  try {
    await serverApi_authOnly.post('/api/tests/test/answer', {
      answerId: answer.answerId,
      qIndex,
      testId
    });
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  if (isLast) {
    let res;
    try {
      res = await serverApi_authOnly.get('/api/tests/test/answer');
    } catch (err) {
      dispatch({ type: DIALOG, payload: dialogPayload(err) });
      return;
    }
    dispatch({ type: RESULT, payload: `${res.data}` });
  }
};

export const resetResult = () => dispatch => {
  dispatch({ type: RESULT, payload: null });
};

export const getWords = () => async dispatch => {
  let res;
  try {
    res = await serverApi_authOnly.get('/api/words/list');
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: WORDLIST, payload: res.data });
};

export const user_logout = history => async dispatch => {
  await history.push('/login');
  sessionStorage.clear();
  dispatch({ type: LOGOUT });
  window.location.reload();
};

export const updateUser = (formValues, history) => async dispatch => {
  try {
    await serverApi_authOnly.post('/api/profile/update', formValues);
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }
  dispatch({ type: UPDATE_USER });
  dispatch({ type: DIALOG, payload: { message: 'Bilgilerini güncelledik.' } });
  history.push('/user');
};

export const updateUserPassword = (formValues, history) => async dispatch => {
  try {
    await serverApi_authOnly.post('/api/profile/update/password', formValues);
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }
  dispatch({ type: UPDATE_USER });
  dispatch({ type: DIALOG, payload: { message: 'Bilgilerini güncelledik.' } });
  history.push('/user');
};

// TODO: revise
export const getTestScore = id => async dispatch => {
  let res;
  try {
    res = await serverApi_authOnly.post('/api/tests/score/test', {
      testId: id
    });
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: SCORE, payload: res.data });
};

// admin actions
export const admin_login = (values, history) => async dispatch => {
  let res;
  try {
    res = await serverApi_loginAdmin.post('/api/admin/login', values);
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: LOGIN_A, payload: res.data });
  history.push('/dem');
};

export const admin_post = (values, valType) => async dispatch => {
  let postURL;
  switch (valType) {
    case 'survey':
      postURL = '/api/admin/survey';
      break;
    case 'video':
      postURL = '/api/admin/video';
      break;
    case 'word':
      postURL = '/api/admin/word';
      break;
    case 'subject':
      postURL = '/api/admin/subject';
      break;
    case 'test':
      postURL = '/api/admin/test';
      break;
    case 'question':
      postURL = '/api/admin/question';
      break;
    case 'answer':
      postURL = '/api/admin/answer';
      break;
    default:
      return;
  }

  try {
    await serverApi_authOnly.post(postURL, values);
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }
  dispatch({ type: POST_A });
  dispatch({ type: DIALOG, payload: { message: 'Yükleme başarılı' } });
};

export const admin_upload = (values, file) => async dispatch => {
  let res;
  try {
    res = await serverApi_uploadAdmin.post(
      '/api/bucket/upload',
      { ...values, file: file },
      { headers: { 'Content-Type': file.type } }
    );
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }
  dispatch({
    type: DIALOG,
    payload: { message: `Yükleme başarılı, url:${res}` }
  });
};

export const admin_preUpload = values => async dispatch => {
  try {
    await serverApi_authOnly.post('/api/bucket/preCheck', values);
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: DIALOG, payload: { message: 'Ön yükleme başarılı' } });
};

export const admin_get = type => async dispatch => {
  let getURL;
  switch (type) {
    case 'users':
      getURL = '/api/admin/users';
      break;
    case 'survey':
      getURL = '/api/admin/survey';
      break;
    case 'video':
      getURL = '/api/admin/video';
      break;
    case 'word':
      getURL = '/api/admin/word';
      break;
    case 'subject':
      getURL = '/api/admin/subject';
      break;
    case 'test':
      getURL = '/api/admin/test';
      break;
    case 'question':
      getURL = '/api/admin/question';
      break;
    case 'answer':
      getURL = '/api/admin/answer';
      break;
    default:
      return;
  }

  let res;
  try {
    res = await serverApi_authOnly.get(getURL);
  } catch (err) {
    dispatch({ type: DIALOG, payload: dialogPayload(err) });
    return;
  }

  dispatch({ type: GET_A, payload: res.data });
};
