import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const radioForms = list => {
  return list.map(item => {
    let label;
    if (item.text) label = item.text;
    else if (item.image) label = <img src={item.image.url} alt='resim' />;
    else if (item.videoId) {
      label = (
        <ReactPlayer
          url={item.videoId.url}
          width='100%'
          height='100%'
          controls={true}
        />
      );
    }

    return (
      <Col md={6} xs={12} key={item._id}>
        <React.Fragment>
          <FormControlLabel
            value={item._id}
            control={
              <Radio
                size='small'
                disableRipple
                disableFocusRipple
                disableTouchRipple
              />
            }
            label={label}
            className='noselect'
          />
        </React.Fragment>
      </Col>
    );
  });
};

const RadioComponent = ({ input: { onChange }, list }) => {
  const [state, setState] = useState(null);

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  const handleChange = event => {
    setState(event.target.value);
  };

  const renderedAnswers = radioForms(list);

  return (
    <FormControl component='fieldset'>
      <RadioGroup
        value={state}
        onChange={handleChange}
        style={{ width: '100%' }}
      >
        <Row className='justify-btwn'>
          {renderedAnswers[0]}
          {renderedAnswers[1]}
        </Row>
        <Row className='justify-btwn'>
          {renderedAnswers[2]}
          {renderedAnswers[3]}
        </Row>
      </RadioGroup>
    </FormControl>
  );
};

export default RadioComponent;
