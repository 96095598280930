import { useState, useEffect } from 'react';

export default function useContentType(videos, tests, words, contentType) {
  const [wordList, setWordList] = useState(null);
  useEffect(() => setWordList(words), [words]);

  let data;
  // prettier-ignore
  switch (contentType) {
    case 'videos': data = videos; break;
    case 'tests': data = tests; break;
    case 'words': data = wordList; break;
    default: break;
  }

  return [data];
}
