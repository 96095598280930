import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import Col from 'react-bootstrap/Col';
import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}

export function initializeGA(pathname) {
  if (process.env.NODE_ENV !== 'production') return null;
  const trackingId = 'UA-146788996-2';
  ReactGA.initialize(trackingId);
  ReactGA.pageview(pathname);
}

/**
 *  filters data to display on the current page
 */
export function currentItems(list = [], page = 1, displayCount = 0) {
  const index = page === 1 ? 0 : displayCount * page - displayCount;
  const range =
    list.length > page * displayCount ? page * displayCount : list.length;
  return list.slice(index, range);
}

/**
 * filters list & sorts matches in descending order
 * if 0 matches, returns []
 */
export function mapSort(list = [], searchParam = '', contentType) {
  if (!searchParam || !searchParam.length) {
    return list
  }

  const re = new RegExp(searchParam, 'gi');
  return contentType === 'words' ? mapSortForWordStr(re, list) : mapSortForTitle(re, list);
}

function mapSortForWordStr(regExp, list = []) {
  const filteredList = filterForWordStr(regExp, list)
  return sortForWordStr(regExp, filteredList)
}

function mapSortForTitle(regExp, list = []) {
  const filteredList = filterForTitle(regExp, list)
  return sortForTitle(regExp, filteredList)
}

function filterForWordStr(regExp, list = []) {
  let _list = [];

  list.forEach(item => {
    if (item['wordStr'].match(regExp)) _list.push(item);
  });

  return _list
}

function filterForTitle(regExp, list = []) {
  let _list = [];

  for (const item of list) {
    if (item['title'].match(regExp)) {
      _list.push(item);
      continue
    }

    if (item['description'] && item['description'].match(regExp)) {
      _list.push(item);
    }
  };

  return _list
}

function sortForWordStr(regExp, list = []) {
  return list.sort((a, b) => {
    const match_a = a['wordStr'].match(regExp);
    const match_b = b['wordStr'].match(regExp);

    const _a = match_a ? match_a.length : 0
    const _b = match_b ? match_b.length : 0

    if (_b < 1) return a;
    if (_a < 1) return b;
    if (_a > _b) return a;
    return b;
  });
}

function sortForTitle(regExp, list = []) {
  return list.sort((a, b) => {
    let descriptionMatch_a = 0
    let descriptionMatch_b = 0

    if (a['description'] && b['description']) {
      descriptionMatch_a = a['description'].match(regExp);
      descriptionMatch_b = b['description'].match(regExp);
  
      descriptionMatch_a = descriptionMatch_a ? descriptionMatch_a.length : 0
      descriptionMatch_b = descriptionMatch_b ? descriptionMatch_b.length : 0
    }

    let titleMatch_a = a['title'].match(regExp);
    let titleMatch_b = b['title'].match(regExp);

    titleMatch_a = titleMatch_a ? titleMatch_a.length : 0
    titleMatch_b = titleMatch_b ? titleMatch_b.length : 0

    const _a = titleMatch_a > descriptionMatch_a ? titleMatch_a : descriptionMatch_a
    const _b = titleMatch_b > descriptionMatch_b ? titleMatch_b : descriptionMatch_b

    if (_b < 1) return a;
    if (_a < 1) return b;
    if (_a > _b) return a;
    return b;
  });
}

export function Loading(props) {
  if (props.error) {
    return (
      <div>
        hata! <button onClick={props.retry}>yeniden dene</button>
      </div>
    );
  } else if (props.timedOut) {
    return (
      <div>
        galiba bir hata oluştu..
        <button onClick={props.retry}>yeniden dene</button>
      </div>
    );
  } else if (props.pastDelay) {
    return <div>yükleniyor..</div>;
  } else {
    return null;
  }
}

export const ShowAllBtn = ({ linkTo, txt }) => {
  const chevronStyle = {
    color: '#161211',
    fontSize: '30',
    verticalAlign: 'middle'
  };

  return (
    <Col sm='auto' xs={12} className='no-pad'>
      <Link className='btn btn-underlined txt-md no-pad align-ttop' to={linkTo}>
        {txt}&nbsp;
        <ChevronRightIcon style={chevronStyle} />
      </Link>
    </Col>
  );
};
