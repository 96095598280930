import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Carousel from '../Helpers/Carousel';
import { videoWrapper } from '../Helpers/wrappers';
import * as actions from '../../actions';

const sliceData = (data, currentVideo) => {
  let currentIndex = currentVideo.order - 1;
  let lastIndex = data.length - 1;

  let range;
  switch (currentIndex) {
    case 0:
      range = [0, 3];
      break;
    case lastIndex:
      range = [lastIndex - 2, lastIndex + 1];
      break;
    default:
      range = [currentIndex - 1, currentIndex + 2];
      break;
  }
  return data.slice(range[0], range[1]);
};

const BottomPanel = ({ currentVideo, data, progress, getVideo, history }) => {
  const [carousel, setCarousel] = useState(null);

  const handleClick = (id, history) => {
    getVideo(id, history);
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (!data || !progress || !currentVideo) return;

    let slicedData = sliceData(data, currentVideo);
    let wrappedData = slicedData.map(item => {
      let isWatched = progress.videoIds.includes(item._id);
      let isCurrent = currentVideo._id === item._id;
      return videoWrapper(item, isWatched, handleClick, history, isCurrent);
    });
    setCarousel(
      <Carousel items={wrappedData} count={[1.2, 2, 3, 3]} infinite={false} />
    );
    //eslint-disable-next-line
  }, [data, progress, currentVideo]);

  return (
    <Row className='mt-25'>
      <Col xs={12} className='no-pad'>
        <div id='Bottom-carousel'>{carousel}</div>
      </Col>
    </Row>
  );
};

function mapStateToProps(data) {
  return {
    currentVideo: data.video,
    data: data.videoList,
    progress: data.progress
  };
}

export default connect(mapStateToProps, actions)(withRouter(BottomPanel));
