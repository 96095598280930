import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PageNav from '../../Helpers/PageNav';
import { newsWrapper } from '../../Helpers/wrappers';
import { currentItems } from '../../Helpers/helpers';
import * as actions from '../../../actions';

const wrapper = (data, page, displayCount) => {
  const list = currentItems(data, page, displayCount);
  const wrappedList = [];

  list.map(post => wrappedList.push(newsWrapper(post)));
  return wrappedList;
};

const pageNav = (page, data, displayCount, handlePage) => {
  return (
    <PageNav
      currentPage={page}
      length={data.length}
      displayCount={displayCount}
      handlePage={handlePage}
    />
  );
};

const New = ({ data, getNews }) => {
  const [renderList, setRenderList] = useState(null);
  const [navButtons, setNavButtons] = useState(null);
  const [page, setPage] = useState(1);
  const displayCount = 12;

  const handlePage = n2Add => {
    setPage(page + n2Add);
  };

  const memoizedNews = useCallback(() => {
    getNews();
  }, [getNews]);

  useEffect(() => {
    if (!data) {
      memoizedNews();
    } else {
      setRenderList(wrapper(data, page, displayCount));
      setNavButtons(pageNav(page, data, displayCount, handlePage));
    }
    //eslint-disable-next-line
  }, [data, page, memoizedNews]);

  return (
    <Container className='mt-0 no-pad'>
      <Row>
        <Col xs={12}>
          <div
            className='txt-lg-2 txt-bold txt-green pt-15 pb-10'
            style={{ textAlign: 'left' }}
          >
            yeni
          </div>
        </Col>
        <Col xs={12} className='mt-10'>
          <div className='paper'>
            <Row className='txt-ctr justify-even pt-25 pb-40 pl-25 pr-25'>
              <Col
                xs={12}
                className='paper back-lt-green no-pad pt-25 pb-40 mb-40'
              >
                <Row>{renderList}</Row>
              </Col>
              <Col xs={12} className='txt-r'>
                {navButtons}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

function mapStateToProps(data) {
  return { data: data.news };
}

export default connect(mapStateToProps, actions)(New);
