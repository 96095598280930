import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function CheckLogin({ data }) {
  const [returnObj, setReturnObj] = useState(null);
  const path = window.location.pathname;
  const token = window.sessionStorage.token ? true : false;
  const userLogin = <Redirect to='/login' />;
  const userHome = <Redirect to='/user' />;
  const adminLogin = <Redirect to='/dem/login' />;

  useState(() => {
    if (path.includes('/user')) {
      if (!token) {
        setReturnObj(userLogin);
      } else if (!data) setReturnObj(userHome);
    } else if (path === '/dem') {
      if (!token) setReturnObj(adminLogin);
    }
  }, [path, token, data]);

  return returnObj;
}

function mapStateToProps(data) {
  return { data: data.login };
}

export default connect(mapStateToProps)(CheckLogin);
