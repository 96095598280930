import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';

const userHeader = user => {
  const userType = user.type === 'Student' ? 'ögrenci' : 'ögretmen';
  const userName = user.name + ' ' + user.surname.toLocaleUpperCase('TR');

  return (
    <div className='pt-50 pb-40'>
      <div className='back-green circle-125 txt-white txt-5xl txt-bold-mx txt-ctr center-hr'>
        {user.name.trim()[0].toLocaleUpperCase('TR')}
      </div>
      <div className='txt-lg txt-bold txt-ctr mt-25'>{userName}</div>
      <div className='txt-md txt-ctr mt-10 word-brk'>{user.email}</div>
      <div className='txt-sm txt-lt txt-gray txt-ctr mt-10'>{userType}</div>
    </div>
  );
};

const wrapper = (current, list) => {
  const navColumn = [];

  list.forEach(({ nav, action, text }) => {
    let className, subClass, style;
    if (current !== nav) {
      className =
        'btn align-l no-border-rad border-tb1-gray cursor-pntr p-10 pt-15 pb-15 mb-1n';
      subClass = 'txt-lg';
      style = { color: '#161211', fontSize: '30', verticalAlign: 'baseline' };
    } else {
      className =
        'btn align-l no-border-rad border-tb1-green cursor-pntr p-10 pt-15 pb-15  mb-1n z-2';
      subClass = 'txt-lg txt-green';
      style = { color: '#2d7373', fontSize: '30', verticalAlign: 'baseline' };
    }

    navColumn.push(
      <Col xs={12} key={nav} className={className} onClick={action}>
        <Row className='justify-btwn no-wrap center-hr'>
          <Col xs={10}>
            <div className={subClass}>{text}</div>
          </Col>
          <Col xs='auto'>
            <ChevronRightIcon style={style} />
          </Col>
        </Row>
      </Col>
    );
  });
  return navColumn;
};

const Nav = ({ data, handleClick, user_logout, history }) => {
  const [userRender, setUserRender] = useState(null);
  const [current, setCurrent] = useState(null);
  const [nav, setNav] = useState(null);

  const handleAction = section => {
    handleClick(section);
    setCurrent(section);
  };

  const navList = [
    {
      nav: 'Update',
      action: () => handleAction('Update'),
      text: 'profil ayarlarım'
    },
    {
      nav: 'Past',
      action: () => handleAction('Past'),
      text: 'geçmişim'
    },
    // {
    //   nav: 'New',
    //   action: () => handleAction('New'),
    //   text: 'yeni'
    // },
    {
      nav: history,
      action: () => user_logout(history),
      text: 'çıkış'
    }
  ];

  useEffect(() => {
    if (!data) return;
    setUserRender(userHeader(data.user));
    setNav(wrapper(current, navList));
    //eslint-disable-next-line
  }, [data, current]);

  return (
    <Row className='paper ml-0 mr-0'>
      <Col xs={12}>{userRender}</Col>
      <Col xs={12}>
        <Row>{nav}</Row>
      </Col>
    </Row>
  );
};

function mapStateToProps(data) {
  return { data: data.userData };
}

export default connect(mapStateToProps, actions)(withRouter(Nav));
