import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PasswordComponent } from '../../../Helpers/FieldComponents';
import { _isPassword } from '../../../Helpers/Validators';
import * as actions from '../../../../actions';

const validate = values => {
  const errors = {};
  const fields = ['oldPassword', 'newPassword', 'passwordCheck'];

  fields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Zorunlu alan.';
    } else if (!_isPassword(values[field])) {
      errors[field] = 'Şifre en az 6 en fazla 12 karakterden oluşmalı.';
    }
  });

  if (values.oldPassword === values.newPassword) {
    errors.newPassword = 'Yeni ve eski şifre farklı olmalı.';
  }

  if (values.newPassword !== values.passwordCheck) {
    errors.passwordCheck = 'Şifre ve şifre tekrarı aynı olmalı.';
  }

  return errors;
};

let ResetForm = ({
  updateUserPassword,
  formValues,
  handleSubmit,
  history,
  invalid,
  submitting,
  pristine
}) => {
  return (
    <form
      onSubmit={handleSubmit(() =>
        updateUserPassword(formValues.values, history)
      )}
    >
      <Row className='mb-40'>
        <Col md={6} xs={12} className='mt-25'>
          <Field
            label='eski şifre'
            name='oldPassword'
            component={PasswordComponent}
          />
        </Col>
        <Col md={6} xs={12} className='mt-25'>
          <Field
            label='yeni şifre'
            name='newPassword'
            component={PasswordComponent}
          />
        </Col>
        <Col md={{ span: 6, offset: 6 }} xs={12} className='mt-25'>
          <Field
            label='yeni şifre (tekrar)'
            name='passwordCheck'
            component={PasswordComponent}
          />
        </Col>
        <Col md={6} xs={12} className='mt-40'>
          <div>
            <button
              className='btn-green btn-full txt-md txt-ctr'
              type='submit'
              disabled={invalid || submitting || pristine}
            >
              şifre güncelle
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

ResetForm = reduxForm({
  form: 'resetForm',
  validate
})(ResetForm);

function mapStateToProps(state) {
  return { formValues: state.form.resetForm };
}

export default connect(mapStateToProps, actions)(withRouter(ResetForm));
