import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { mapSort } from '../Helpers/helpers';
import Redirect2Page from '../Helpers/Redirect2Page';
import SearchFilter from '../Helpers/SearchFilter';
import Results from './Results';
import Letters from '../Helpers/Letters';
import useContentType from '../../hooks/useContentType';
import * as actions from '../../actions';

const filterCompleted = (contentType, data, progress) => {
  let type;
  // prettier-ignore
  switch (contentType) {
    case 'videos': type = 'videoIds'; break;
    case 'tests': type = 'testIds'; break;
    case 'words': type = 'wordIds'; break;
    default: break;
  }

  return data.filter(item => !progress[type].includes(item._id));
};

const List = ({ videos, tests, words, progress, getWordsByChar }) => {
  const { contentType } = useParams();
  const [data] = useContentType(videos, tests, words, contentType);
  const [searchResults, setSearchResults] = useState(data);
  useEffect(() => setSearchResults(data), [data]);

  const handleSearch = (searchString, newItemsOnly) => {
    if (!data || !progress) return [];

    let _data = data;
    if (newItemsOnly) _data = filterCompleted(contentType, data, progress);
    if (searchString) _data = mapSort(_data, searchString, contentType);

    setSearchResults(_data);
  };

  return (
    <div>
      <div className='mt-25'>
        <Redirect2Page />
      </div>
      <SearchFilter contentType={contentType} handleSearch={handleSearch} />
      {contentType === 'words' && (
        <div className='mb-60'>
          <Letters handleClick={getWordsByChar} />
        </div>
      )}
      <Results contentType={contentType} results={searchResults} />
    </div>
  );
};

function mapStateToProps(data) {
  return {
    videos: data.videoList,
    tests: data.testList,
    words: data.wordList,
    progress: data.progress
  };
}

export default connect(mapStateToProps, actions)(List);
