import React from 'react';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

const responsive = count => ({
  mobile: { breakpoint: { max: 575, min: 0 }, items: count[0] },
  mobileLg: { breakpoint: { max: 991, min: 576 }, items: count[1] },
  tablet: { breakpoint: { max: 1199, min: 992 }, items: count[2] },
  desktop: { breakpoint: { max: 3000, min: 1200 }, items: count[3] }
});

const Infinite = ({
  items,
  count = [3, 3, 3, 3],
  infinite = false,
  addTransform = -15
}) => {
  return (
    <Carousel
      additionalTransfrom={addTransform}
      autoPlaySpeed={3000}
      containerClass='container'
      draggable
      itemClass=''
      minimumTouchDrag={80}
      removeArrowOnDeviceType={['mobile', 'mobileLg']}
      renderButtonGroupOutside
      renderDotsOutside
      responsive={responsive(count)}
      slidesToSlide={1}
      ssr
      swipeable
      infinite={infinite}
    >
      {items}
    </Carousel>
  );
};

export default Infinite;
