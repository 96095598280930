import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Personal from './Personal';
import Password from './Password';

import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const UpdateForm = () => {
  const style = { color: '#161211', fontSize: '30', verticalAlign: 'baseline' };

  return (
    <Container className='mt-0 no-pad'>
      <Row>
        <Col xs={12}>
          <div
            className='txt-lg-2 txt-bold txt-green pt-15 pb-10'
            style={{ textAlign: 'left' }}
          >
            profil ayarlarım
          </div>
        </Col>
        <Col xs={12}>
          <ExpansionPanel square={true} className='mt-10'>
            <ExpansionPanelSummary
              expandIcon={<ChevronRightIcon style={style} />}
            >
              <div className='txt-lg p-10'>kişisel bilgilerim</div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Personal />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Col>
        <Col xs={12}>
          <ExpansionPanel square={true} className='mt-10'>
            <ExpansionPanelSummary
              expandIcon={<ChevronRightIcon style={style} />}
            >
              <div className='txt-lg p-10'>şifre güncelleme</div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Password />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Col>
      </Row>
    </Container>
  );
};

export default UpdateForm;
