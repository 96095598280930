import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import * as actions from '../../../../actions';
import PastVideos from './PastVideos';
import PastTests from './PastTests';

import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

// type -> 'videoIds' || 'testIds'
const filterData = (data, type, progress) => {
  let filteredData = [];
  for (let item of data) {
    if (progress[type].includes(item._id)) filteredData.push(item);
  }
  return filteredData;
};

const Past = ({ progress, videos, tests, getTest, getVideo, history }) => {
  const style = { color: '#161211', fontSize: '30', verticalAlign: 'baseline' };
  const [filteredTests, setFilteredTests] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);

  const memoizedData = useCallback(() => {
    setFilteredVideos(filterData(videos, 'videoIds', progress));
    setFilteredTests(filterData(tests, 'testIds', progress));
  }, [videos, tests, progress]);

  useEffect(() => {
    if (!videos || !tests || !progress) return;
    memoizedData();
  }, [memoizedData, videos, tests, progress]);

  return (
    <Container className='mt-0 no-pad'>
      <Row>
        <Col xs={12}>
          <div
            className='txt-lg-2 txt-bold txt-green pt-15 pb-10'
            style={{ textAlign: 'left' }}
          >
            geçmişim
          </div>
        </Col>
        <Col xs={12}>
          <ExpansionPanel square={true} className='mt-10'>
            <ExpansionPanelSummary
              expandIcon={<ChevronRightIcon style={style} />}
            >
              <div className='txt-lg p-10'>videolar</div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PastVideos filteredVideos={filteredVideos} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel square={true} className='mt-10'>
            <ExpansionPanelSummary
              expandIcon={<ChevronRightIcon style={style} />}
            >
              <div className='txt-lg p-10'>testler</div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PastTests filteredTests={filteredTests} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Col>
      </Row>
    </Container>
  );
};

function mapStateToProps(data) {
  return {
    progress: data.progress,
    videos: data.videoList,
    tests: data.testList
  };
}

export default connect(mapStateToProps, actions)(withRouter(Past));
