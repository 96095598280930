import React, { useEffect, useState } from 'react';

import PageNav from '../components/Helpers/PageNav';

export default function usePageNav(data, count) {
  const [navButtons, setNavButtons] = useState(null);
  const [page, setPage] = useState(1);

  const handlePage = n2Add => {
    setPage(page + n2Add);
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (!data) return;
    let pageCount = Math.ceil(data.length / count);
    if (pageCount < page) setPage(1);
  }, [data, count, page]);

  useEffect(() => {
    if (!data) return;
    setNavButtons(
      <PageNav
        currentPage={page}
        length={data.length}
        displayCount={count}
        handlePage={handlePage}
      />
    );
    // eslint-disable-next-line
  }, [page, data]);

  return { page, navButtons };
}
