import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { renderFlag } from '../Helpers/wrappers';

const Progress = ({ data }) => {
  const [videoCount, setVideoCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [testCount, setTestCount] = useState(0);

  const txtClass1 = 'txt-green txt-lg txt-bold txt-no-wrap';
  const txtClass2 = 'txt-lt-gray txt-lg txt-lt txt-no-wrap';

  useEffect(() => {
    if (!data) return;
    setVideoCount(data.videoCount);
    setWordCount(data.wordCount);
    setTestCount(data.testCount);
  }, [data]);

  return (
    <Container>
      <Row className='justify-ctr'>
        <Col xl={10} lg={11} xs={12} className='mt-40 mb-n15'>
          <div className='ml-30 txt-lg-2 txt-bold-mx'>
            Hoşgeldin {sessionStorage.getItem('name')},
            <div className='txt-gray txt-lg txt-bold pt-25'>Bugüne kadar;</div>
          </div>
        </Col>
        <Col xl={10} lg={11} xs={12}>
          <Row className='justify-even'>
            <Col lg={4} sm={6} className='no-pad p-30'>
              <div>{renderFlag('video', videoCount, txtClass1, txtClass2)}</div>
            </Col>
            <Col lg={4} sm={6} className='no-pad p-30'>
              <div>{renderFlag('word', wordCount, txtClass1, txtClass2)}</div>
            </Col>
            <Col lg={4} sm={6} className='no-pad p-30'>
              <div>{renderFlag('test', testCount, txtClass1, txtClass2)}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

function mapStateToProps(data) {
  return { data: data.progress };
}

export default connect(mapStateToProps)(Progress);
