import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';

import { LOGOUT } from '../actions/types';

import dialogReducer from './dialogReducer';
import newsReducer from './newsReducer';
import loginReducer from './loginReducer';
import surveyReducer from './surveyReducer';
import forgotReducer from './forgotReducer';
// profileReducers
import progressReducer from './profile/progressReducer';
import videosReducer from './profile/videoReducer';
import userDataReducer from './profile/userDataReducer';
import testsReducer from './profile/testReducer';
import wordReducer from './profile/wordReducer';
import videoReducer from './videoReducer';
import testReducer from './testReducer';
import qReducer from './qReducer';
import resultReducer from './resultReducer';
import scoreReducer from './scoreReducer';
// adminReducers
import adminLoginReducer from './admin/loginReducer';
import adminPostReducer from './admin/postReducer';
import adminGetReducer from './admin/getReducer';

const appReducer = combineReducers({
  dialog: dialogReducer,
  news: newsReducer,
  login: loginReducer,
  survey: surveyReducer,
  forgot: forgotReducer,

  progress: progressReducer,
  videoList: videosReducer,
  testList: testsReducer,
  wordList: wordReducer,
  userData: userDataReducer,
  video: videoReducer,
  test: testReducer,
  question: qReducer,
  result: resultReducer,
  score: scoreReducer,

  loginA: adminLoginReducer,
  postA: adminPostReducer,
  getA: adminGetReducer,

  form: reduxForm
});

export default (state, action) => {
  if (action.type === LOGOUT) state = undefined;
  return appReducer(state, action);
};
