import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions';
import First from './First';
import Middle from './Middle';
// import Last from './Last';
import { initializeGA } from '../Helpers/helpers';

initializeGA('Landing Page');

const Landing = ({ getNews }) => {
  const memoizedNews = useCallback(() => {
    getNews();
  }, [getNews]);

  useEffect(() => {
    memoizedNews();
  }, [memoizedNews]);

  return (
    <div>
      <First />
      <Middle />
      {/* <Last /> */}
    </div>
  );
};

export default connect(null, actions)(Landing);
