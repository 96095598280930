/**
 * - trims extra whitespace
 * - capitalizes first chars
 * @param string
 */
export function reformatName(name) {
  return name
    .toLocaleLowerCase('tr')
    .replace(/[\s]+/g, ' ')
    .replace(/(^.)/gi, match => match.toLocaleUpperCase('tr'))
    .replace(/(\s.)/gi, match => match.toLocaleUpperCase('tr'))
    .trim();
}

/**
 * - trims extra whitespace
 * - capitalizes all chars
 * @param string
 */
export function reformatSurname(surname) {
  return surname.toLocaleUpperCase('tr').replace(/[\s]+/g, ' ').trim();
}

/**
 * - trims extra whitespace
 * - lowercases all
 * @param string
 */
export function reformatText(text) {
  return text.toLocaleLowerCase('tr').replace(/[\s]+/g, ' ').trim();
}

/**
 * - trims extra whitespace
 * - lowercases all
 * @param string
 */
export function reformatWord(word) {
  return word.toLocaleLowerCase('tr').replace(/[\s]+/g, ' ').trim();
}
