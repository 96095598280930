import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import {
  TextComponent,
  PasswordComponent,
  CheckboxComponent
} from './Helpers/FieldComponents';
import { _isEmail, _isPassword } from './Helpers/Validators';
import Redirect2Page from './Helpers/Redirect2Page';
import * as actions from '../actions';

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Zorunlu alan.';
  } else if (!_isEmail(values.email)) {
    errors.email = 'Geçersiz e-posta adresi.';
  }
  if (!values.password) {
    errors.password = 'Zorunlu alan.';
  } else if (!_isPassword(values.password)) {
    errors.password = 'Şifre en az 6 en fazla 12 karakterden oluşmalı.';
  }

  return errors;
};

let Login = ({
  user_login,
  history,
  formValues,
  handleSubmit,
  invalid,
  submitting,
  pristine
}) => {
  return (
    <div>
      <div className='mt-25 mb-n30'>
        <Redirect2Page page='/' />
      </div>
      <Container>
        <Row className='align-ctr justify-even mb-120'>
          <Col lg={6} sm={10} xs={12}>
            <div className='p-30'>
              <img
                src='https://storage.googleapis.com/bukalemun-bucket/images/login.jpg'
                alt='resim'
                width='100%'
              />
            </div>
          </Col>
          <Col lg={6} sm={10} xs={12}>
            <div className='paper-lg pb-25'>
              <Row className='justify-even pb-40'>
                <Col xs={12}>
                  <div className='txt-lg-2 txt-bold txt-ctr pt-50 pb-40'>
                    giriş yap
                  </div>
                </Col>
                <Col lg={10} sm={11} xs={12}>
                  <form
                    onSubmit={handleSubmit(() =>
                      user_login(formValues.values, history)
                    )}
                  >
                    <Col xs={12} className='pb-25'>
                      <Field
                        label='e-posta adresi'
                        name='email'
                        component={TextComponent}
                        autoComplete='true'
                      />
                    </Col>
                    <Col xs={12} className='pb-15'>
                      <Field
                        label='şifre'
                        name='password'
                        component={PasswordComponent}
                        autoComplete='true'
                      />
                    </Col>
                    <Col sm={12} className='mb-15'>
                      <Row className='justify-btwn mt-10 mb-15'>
                        <Col sm={4} xs={12} id='Beni-hatirla'>
                          <span className='txt-no-wrap txt-ctr'>
                            <CheckboxComponent
                              checked
                              label='beni hatırla'
                              input={{ onChange: () => {} }}
                            />
                          </span>
                        </Col>
                        <Col sm='auto' xs={12}>
                          <span
                            className='btn txt-sm btn-underlined txt-ctr no-pad p-t5'
                            type='button'
                            onClick={() => history.push('/forgotPassword')}
                          >
                            şifremi unuttum
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} className='mt-25'>
                      <button
                        className='btn-green btn-full'
                        type='submit'
                        disabled={invalid || submitting || pristine}
                      >
                        giriş yap
                      </button>
                    </Col>
                    <Col sm={12} className='mt-25 mb-15'>
                      <span className='txt-sm'>bir hesabın yok mu?</span>
                      <span
                        className='btn btn-underlined align-base ml-n6'
                        type='button'
                        onClick={() => history.push('/signUp')}
                      >
                        <span className='txt-sm txt-green'>kayıt ol</span>
                      </span>
                    </Col>
                  </form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Login = reduxForm({
  form: 'loginForm',
  validate,
  destroyOnUnmount: false
})(Login);

function mapStateToProps(state) {
  return { formValues: state.form.loginForm };
}

export default connect(mapStateToProps, actions)(withRouter(Login));
