import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as actions from '../../actions';

const ltClass = 'btn-lt-green btn-full';
const regClass = 'btn-green btn-full';

const _button = (id, type, fn, hist, isLite = false) => {
  if (type === 'redo') {
    return (
      <Col lg={4} md={7} sm={8} xs={12} className='mt-15'>
        <button className={ltClass} onClick={() => fn(id)}>
          testi tekrar et
        </button>
      </Col>
    );
  } else if (type === 'sum') {
    return (
      <Col lg={4} md={7} sm={8} xs={12} className='mt-15'>
        <button className={regClass} onClick={() => fn(id)}>
          genel değerlendirme testini çöz
        </button>
      </Col>
    );
  } else {
    return (
      <Col lg={4} md={7} sm={8} xs={12} className='mt-15'>
        <button
          className={isLite ? ltClass : regClass}
          onClick={() => fn(id, hist)}
        >
          videolara devam et
        </button>
      </Col>
    );
  }
};

const Result = ({
  reset,
  result,
  tests,
  videos,
  current,
  getTest,
  getVideo,
  history
}) => {
  const successImgSrc =
    'https://storage.googleapis.com/bukalemun-bucket/images/test-result.png';
  const failImgSrc =
    'https://storage.googleapis.com/bukalemun-bucket/images/failed-test-result.png';

  const [score, setScore] = useState(null);
  const [retake, setRetake] = useState(null);
  const [summary, setSummary] = useState(null);
  const [video, setVideo] = useState(null);
  const [image, setImage] = useState(null);

  const handleTest = async id => {
    await getTest(id, history);
    reset();
  };

  function renderBtns() {
    score !== 100
      ? setRetake(_button(current.test._id, 'redo', handleTest, history))
      : setRetake(null);

    let isLite = false;
    if (current.test.isReviewNext) {
      setSummary(
        _button(tests[current.test.order]._id, 'sum', handleTest, history)
      );
      isLite = true;
    } else setSummary(null);

    if (videos.length > current.test.courseOrder) {
      setVideo(
        _button(
          videos[current.test.courseOrder]._id,
          'res',
          getVideo,
          history,
          isLite
        )
      );
    } else setVideo(null);
  }

  useEffect(() => {
    if (!result) return;
    setScore(result);
  }, [result]);

  useEffect(() => {
    if (!result || !score || !tests || !videos || !current) return;
    renderBtns();
    setImage(
      parseInt(score) > 50 ? (
        <img src={successImgSrc} alt='resim' width='100%' />
      ) : (
        <img src={failImgSrc} alt='resim' width='100%' />
      )
    );
    // eslint-disable-next-line
  }, [tests, videos, current, result, score]);

  return (
    <Row className='justify-ctr'>
      <Col lg={4} sm={5} xs={7}>
        {image}
      </Col>
      <Col xs={12}>
        <Row className='justify-ctr'>
          <Col lg={2} md={4} sm={6} xs={8} className='mt-25 mb-40'>
            <div className='txt-lg txt-ctr'>puanın </div>
            <div className='txt-drk-orng txt-lg-2 txt-ctr border-tb1-drk-orng mt-10 p-t5 p-b5'>
              {score} <span className='txt-drk-orng txt-md'>/ 100</span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row className='justify-ctr center-hr wrap-rev'>
          {retake}
          {video}
          {summary}
        </Row>
      </Col>
    </Row>
  );
};

function mapStateToProps(data) {
  return {
    result: data.result,
    tests: data.testList,
    videos: data.videoList,
    current: data.test
  };
}

export default connect(mapStateToProps, actions)(withRouter(Result));
