import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions';
import { _isEmail } from '../Helpers/Validators';
import { TextComponent } from '../Helpers/FieldComponents';
import Redirect2Page from '../Helpers/Redirect2Page';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!_isEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

let ForgotPassword = ({
  history,
  handleSubmit,
  getResetLink,
  formValues,
  invalid,
  submitting,
  pristine
}) => {
  return (
    <div>
      <div className='mt-25 mb-n30'>
        <Redirect2Page page='/' />
      </div>
      <Container>
        <Row className='align-ctr justify-ctr'>
          <Col sm={6} xs={11}>
            <Row className='align-ctr p-30 mt-40'>
              <Col lg={11} xs={12} className='mt-n10'>
                <img
                  src='https://storage.googleapis.com/bukalemun-bucket/images/forgot.jpg'
                  alt='resim'
                  width='100%'
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6} xs={12} className='mb-60'>
            <form
              onSubmit={handleSubmit(() => {
                getResetLink(formValues.values, history);
              })}
            >
              <Row className='paper-lg mt-0 ml-0 mr-0 pt-80 pb-100 justify-ctr'>
                <Col lg={10} xs={11}>
                  <div className='txt-lg-2 txt-bold txt-ctr'>
                    şifre yenileme isteği
                  </div>
                </Col>
                <Col lg={10} xs={11} className='mt-25'>
                  <p className='txt-md'>
                    Şifreni unuttuysan üzülme, kolayca buradan yenileyebilirsin.
                    Bukalemun’a kayıt olduğun e-posta adresini gir. Mailine
                    gelen bağlantıya tıklayarak şifreni yenile.
                  </p>
                </Col>
                <Col lg={10} xs={11} className='mt-40'>
                  <Field
                    label='e-posta adresi'
                    name='email'
                    component={TextComponent}
                  />
                </Col>
                <Col lg={10} xs={11} className='mt-25'>
                  <button
                    type='submit'
                    disabled={invalid || submitting || pristine}
                    className='btn-green btn-full'
                  >
                    link gönder
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ForgotPassword = reduxForm({
  form: 'forgotPassword',
  validate
})(ForgotPassword);

function mapStateToProps(state) {
  return { formValues: state.form.forgotPassword };
}

export default connect(mapStateToProps, actions)(withRouter(ForgotPassword));
