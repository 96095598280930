import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Redirect2Page from './Helpers/Redirect2Page';
import { initializeGA } from './Helpers/helpers';
import kisiseller from '../data/kisisel.json';

const UnderConstruction = () => {
  return (
    <div>
      <Container>
        <Row className='justify-ctr'>
          <Col>
            <img
              src='https://storage.googleapis.com/bukalemun-bucket/images/coming-soon.png'
              alt='resim'
              width='100%'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const About = () => {
  initializeGA('Biz');

  return (
    <div>
      <div className='mt-25 mb-n30'>
        <Redirect2Page page='/' />
      </div>
      <Container>
        <Row className='justify-btwn pt-80 pb-60'>
          <Col sm={6}>
            <Row className='justify-btwn'>
              <Col sm='auto'>
                <span className=' txt-xxl txt-bold-mx'>
                  <span className='txt-drk-orng txt-xxl txt-bold-mx'>
                    biz&nbsp;
                  </span>
                  kimiz&nbsp;?
                </span>
              </Col>
              <Col sm>
                <div className='back-lg-blue flex-rect'></div>
              </Col>
              <Col sm={12}>
                <div className='txt-md pt-15'>
                  Bizler, farklı uzmanlıkları ile bir araya gelmiş Dem Derneği
                  gönüllüleri ve çalışanlarıyız. Bukalemun'da farklı görevler
                  alarak ortak bir hayal için yola çıktık. Sağır ve işitme
                  engellilerin istihdamı, işaret dilinin yaygınlaştırılması ve
                  bu dili öğrenmenin dijital platformda renkli bir yolunu bulma
                  hedefi ile ilerledik ve rengarenk Bukalemun doğdu!
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <img
              src='https://storage.googleapis.com/bukalemun-bucket/images/us.jpg'
              alt='resim'
              width='100%'
              className='pt-25'
            />
          </Col>
        </Row>
        <Row className='justify-btwn pb-60'>
          <Col sm='auto'>
            <span className=' txt-xxl txt-bold-mx'>
              <span className='txt-drk-orng txt-xxl txt-bold-mx'>
                ekibimiz&nbsp;
              </span>
              ile tanış!
            </span>
          </Col>
          <Col sm>
            <div className='back-lg-blue flex-rect'></div>
          </Col>
          <Col sm={12}>
            <Row className='pt-25'>
              {kisiseller.map((kisi, index) => (
                <Col md={3} sm={4} key={index}>
                  <div className='p-30'>
                    <img
                      src={kisi.src}
                      alt={kisi.name}
                      width='100%'
                      className='paper-lg-rnd p-15'
                    />
                    <div className='txt-lg txt-ctr txt-bold-mx pt-25'>
                      {kisi.name}
                      <br />
                      {kisi.surname}
                    </div>
                    <div className='txt-sm txt-green txt-bold txt-ctr pt-10'>
                      {kisi.job}
                    </div>
                  </div>
                  <div></div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export { About, UnderConstruction };
