import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Carousel from '../Helpers/Carousel';
import { videoWrapper as wrapper } from '../Helpers/wrappers';
import { ShowAllBtn } from '../Helpers/helpers';
import * as actions from '../../actions';
import useTimeout from '../../hooks/useTimeout';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Videos = ({ videoList, progress, getVideo, history }) => {
  const [videoCount, setVideoCount] = useState(null);
  const [carousel, setCarousel] = useState(null);
  const timedOut = useTimeout(2500);
  const carouselStyle = !timedOut ? { visibility: 'hidden' } : {};

  useEffect(() => {
    if (!videoList || !progress) return;
    setVideoCount(videoList.length);
    let wrappedData = videoList.map(item =>
      wrapper(item, progress.videoIds.includes(item._id), getVideo, history)
    );
    setCarousel(
      <Carousel items={wrappedData} count={[3, 3, 4, 4]} infinite={true} />
    );
    //eslint-disable-next-line
  }, [videoList, progress]);

  return (
    <Container>
      <Row className='justify-btwn mt-60 mb-60'>
        <Col sm={4} xs={12}>
          <span className='txt-lg'>
            Videolar
            <span className='txt-lg txt-gray'> ({videoCount})</span>
          </span>
        </Col>
        <ShowAllBtn linkTo='/user/list/videos' txt='tüm videoları gör' />
        <Row className='ml-0 mr-0 mt-10 no-pad' style={carouselStyle}>
          {carousel}
        </Row>
      </Row>
    </Container>
  );
};

function mapStateToProps(data) {
  return { videoList: data.videoList, progress: data.progress };
}

export default connect(mapStateToProps, actions)(withRouter(Videos));
