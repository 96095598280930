import React from 'react';
import { connect } from 'react-redux';

import Redirect2Page from '../Helpers/Redirect2Page';
import VideoPlayer from './VideoPlayer';
import SidePanel from './SidePanel';
import BottomPanel from './BottomPanel';
import * as actions from '../../actions';
import { initializeGA } from '../Helpers/helpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const Watch = ({ getWords }) => {
  initializeGA('Video Ekranı');

  // const memoizedWords = useCallback(() => {
  //   getWords();
  // }, [getWords]);

  // useEffect(() => {
  //   memoizedWords();
  // }, [memoizedWords]);

  return (
    <div>
      <div className='mt-25 mb-40'>
        <Redirect2Page />
      </div>
      <Container>
        <Row id='Watch-bottom' className='mb-40'>
          <Col lg={9} xs={12} className='ml-0 mr-0'>
            <VideoPlayer />
          </Col>
          <Col lg={3} md={6} sm={7} xs={11} className='ml-0 mr-0 mt-60'>
            <SidePanel />
          </Col>
          <Col lg={9} md={10} sm={11} xs={12} className='ml-0 mr-0'>
            <BottomPanel />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connect(null, actions)(Watch);
