import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { SelectMultiComponent } from '../Helpers/FieldComponents';

const validate = values => {
  const errors = {};
  const fields = ['q1', 'q2', 'q3'];

  fields.forEach(field => {
    if (!values[field]) errors[field] = 'Zorunlu alan.';
  });

  return errors;
};

let Form2 = ({
  survey,
  handleSubmit,
  handlePrevious,
  invalid,
  submitting,
  pristine
}) => {
  // label={survey.multi[1].question}
  // label={survey.multi[2].question}
  // label={survey.multi[0].question}

  return (
    <form onSubmit={handleSubmit}>
      <Row className='align-l mt-25'>
        <Col sm={12} className='mt-40 mb-25'>
          <span className='txt-lg txt-green txt-bold-mx'>kişisel bilgiler</span>
        </Col>
        <Col xs={1}>
          <div className='back-orng circle-25 txt-white txt-ctr p-t1 p-l1'>
            1
          </div>
        </Col>
        <Col lg={11} xs={10}>
          <div className='txt-sm'>{survey.multi[1].question}</div>
        </Col>
        <Col lg={{ span: 11, offset: 1 }} xs={12} className='mb-25'>
          <Field
            name='q1'
            list={survey.multi[1].choices}
            component={SelectMultiComponent}
            placeholder='lütfen cevap/larını seç.'
          />
        </Col>
        <Col xs={1}>
          <div className='back-orng circle-25 txt-white txt-ctr p-t1 p-l1'>
            2
          </div>
        </Col>
        <Col lg={11} xs={10}>
          <div className='txt-sm'>{survey.multi[2].question}</div>
        </Col>
        <Col lg={{ span: 11, offset: 1 }} xs={12} className='mb-25'>
          <Field
            name='q2'
            list={survey.multi[2].choices}
            component={SelectMultiComponent}
            placeholder='lütfen cevap/larını seç.'
          />
        </Col>
        <Col xs={1}>
          <div className='back-orng circle-25 txt-white txt-ctr p-t1 p-l1'>
            3
          </div>
        </Col>
        <Col lg={11} xs={10}>
          <div className='txt-sm'>{survey.multi[0].question}</div>
        </Col>
        <Col lg={{ span: 11, offset: 1 }} xs={12} className='mb-15'>
          <Field
            name='q3'
            list={survey.multi[0].choices}
            component={SelectMultiComponent}
            placeholder='lütfen cevap/larını seç.'
          />
        </Col>
        <Col lg={{ span: 11, offset: 1 }} xs={12}>
          <Row className='mb-60 wrap-rev'>
            <Col lg={6} xs={12} className='mt-25'>
              <button
                onClick={() => handlePrevious()}
                className='btn-gray btn-full'
              >
                geri dön
              </button>
            </Col>
            <Col lg={6} xs={12} className='mt-25'>
              <button
                type='submit'
                disabled={pristine || submitting || invalid}
                className='btn-green btn-full'
              >
                ilerle
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

Form2 = reduxForm({
  form: 'signUp',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(Form2);

function mapStateToProps(state) {
  return { survey: state.survey };
}

export default connect(mapStateToProps)(Form2);
