import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Modal from '../Helpers/Modal';
import ImageSkeleton from './ImageSkeleton';

export const videoWrapper = (
  item,
  isWatched,
  handleClick,
  history,
  isCurrent = false
) => {
  let titleClass = 'txt-md pl-15 mt-25 mb-15 mr-10 ml-10 border-b1-gray';
  let mainClass = isWatched
    ? 'completed-border paper cursor-pntr m-15'
    : 'paper cursor-pntr m-15';

  if (isCurrent) {
    mainClass = 'current-border paper cursor-pntr m-15 back-green';
    titleClass += ' txt-white';
  }

  return (
    <div
      key={item._id}
      onClick={() => handleClick(item._id, history)}
      className={mainClass}
      style={{ textAlign: 'left' }}
    >
      <ImageSkeleton
        src={item.thumbUrl}
        alt='resim'
        width='100%'
        height='100%'
        skWidth='293px'
        skHeight='165px'
      />
      <div className={titleClass}>{item.title}</div>
      {item.description && (
        <p className='pl-15 mr-10 ml-10 txt-sm txt-gray' style={{ height: 50 }}>
          {item.description}
        </p>
      )}
    </div>
  );
};

export const testWrapper = (
  item,
  isIncomplete,
  isCompleted,
  handleClick,
  history,
  results = []
) => {
  const imgClassName = item.isReview ? 'sum-test-thumb' : 'test-thumb';

  let score;
  if (isCompleted && results) {
    const len = results.length;
    for (let n = 0; n < len; n++) {
      if (results[n].testId === item._id) {
        score = results[n].score;
        break;
      }
    }
  }

  let className;
  let style = { textAlign: 'left' };
  if (isIncomplete) {
    className = 'incomplete-border paper cursor-pntr m-15';
  } else if (isCompleted) {
    switch (score) {
      case 100:
        className = 'completed-border paper cursor-pntr m-15';
        break;
      case 0:
        className = 'completed-border-fail paper cursor-pntr m-15';
        break;
      default:
        className = 'completed-border paper cursor-pntr m-15';
        style.borderImage = `linear-gradient(90deg, #2d7373, #2d7373 ${score}%, #909f9f ${score}%) 1`;
        style.borderBottom = style.borderLeft = style.borderRight = 0;
    }
  } else className = 'paper cursor-pntr m-15';

  return (
    <div
      key={item._id}
      onClick={() => handleClick(item._id, history)}
      className={className}
      style={style}
    >
      <div className='txt-lg txt-orng pl-15 mb-n30 pt-10 inl-flex'>
        {item.title}
      </div>
      <div className={imgClassName} />
      {isCompleted && (
        <div className='txt-sm txt-orng align-r pr-10 pb-10 mt-n35'>
          Skor:{'  '}
          {score} / 100
        </div>
      )}
    </div>
  );
};

export const wordWrapper = (item, handleClick, history) => {
  return (
    <div
      key={item._id}
      onClick={() => handleClick(item.videoId, history)}
      className='word-paper paper m-30'
    >
      <div className='word-thumb flex justify-ctr align-ctr'>
        <div className='play-circle'>
          <div className='play-triangle pos-abs mt-10' />
        </div>
      </div>
      <div className='txt-md txt-ctr pt-10'>{item.wordStr}</div>
    </div>
  );
};

export const newsWrapper = item => {
  return (
    <Col lg={4} sm={6} xs={12} key={item._id} className='pt-20'>
      <div className='p-10'>
        <Modal
          src={item.imageURL}
          thumbSrc={item.thumbURL}
          type='image'
          divClass='paper'
        />
      </div>
      {/* <span className=''>{item.title}</span>
      <span className=''>
        {item.subTitle} <a href={item.linkURL}>tıkla!</a>
      </span> */}
    </Col>
  );
};

const vidImg =
  'https://storage.googleapis.com/bukalemun-bucket/images/vid-flag.png';
const wordImg =
  'https://storage.googleapis.com/bukalemun-bucket/images/wrd-flag.png';
const testImg =
  'https://storage.googleapis.com/bukalemun-bucket/images/tst-flag.png';
const scoreImg =
  'https://storage.googleapis.com/bukalemun-bucket/images/sc-flag.png';
/**
 * @param type must be one of 'video', 'test', 'word', 'score'
 * @param txtClass1 className of first text line
 * @param txtClass2 className of second text line
 */
export const renderFlag = (
  type = '',
  data = 0,
  txtClass1 = '',
  txtClass2 = ''
) => {
  let vars = [];
  if (type === 'video') vars = [' eğitim videosu', 'izledin', vidImg];
  if (type === 'word') vars = [' kelime', 'öğrendin', wordImg];
  if (type === 'test') vars = [' test', 'tamamladın', testImg];
  if (type === 'score') vars = [' / 100', 'ortalama skorun', scoreImg];

  return (
    <Container>
      <Row className='paper mr-0 ml-0'>
        <Col xs={12}>
          <div>
            <ImageSkeleton
              src={vars[2]}
              alt='resim'
              width='100%'
              skWidth='240px'
              skHeight='240px'
            />
          </div>
        </Col>
        <Col xs={12}>
          <span className={txtClass1}>
            {data}
            {vars[0]}
          </span>
        </Col>
        <Col xs={12} className='pb-25'>
          <span className={txtClass2}>{vars[1]}</span>
        </Col>
      </Row>
    </Container>
  );
};
