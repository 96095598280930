import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Redirect2Page from '../Helpers/Redirect2Page';
import Question from './Question';
import Result from './Result';
import * as actions from '../../actions';

const Test = ({ data, q, getQuestion, postAnswer, resetResult }) => {
  const [title, setTitle] = useState(null);
  const [count, setCount] = useState(null);
  const [qIndex, setqIndex] = useState(null);
  const [view, setView] = useState(null);

  const resetView = () => setView(null);

  const postQuestion = async answer => {
    let isLast = qIndex === count;
    setView(null);
    await postAnswer(answer, qIndex - 1, data.test._id, isLast);
    isLast ? setView(<Result reset={resetView} />) : getQuestion(data.test._id);
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  const memoizedTest = useCallback(() => {
    setTitle(data.test.title);
    setCount(data.test.questionIds.length);
  }, [data]);

  useEffect(() => {
    if (!data) return;
    resetResult();
    memoizedTest();
    getQuestion(data.test._id);
    // eslint-disable-next-line
  }, [data, memoizedTest]);

  useEffect(() => {
    if (!q) return;
    setqIndex(q.index);
    // eslint-disable-next-line
  }, [q]);

  useEffect(() => {
    if (!qIndex) return;
    setView(
      <Question
        qIndex={qIndex}
        count={count}
        question={q.question}
        post={postQuestion}
      />
    );
    // eslint-disable-next-line
  }, [qIndex, q]);

  return (
    <div>
      <div className='mt-25'>
        <Redirect2Page />
      </div>
      <Container>
        <Row className='ml-0 mr-0 mt-40 mb-60'>
          <Col xs={12} className='txt-lg-2 txt-drk-orng no-pad mb-15'>
            {title}
          </Col>
          <Col xs={12} className='paper mb-100 pb-10'>
            <Row className='justify-ctr mt-40 mb-60'>
              <Col xs={12} className='mt-40 no-pad'>
                {view}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

function mapStateToProps(data) {
  return {
    data: data.test,
    q: data.question
  };
}

export default connect(mapStateToProps, actions)(Test);
