import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';

import Redirect2Page from '../Helpers/Redirect2Page';
import Nav from './Nav';
import Update from './Sections/Update/UpdateForm';
import Past from './Sections/Past/Past';
import New from './Sections/New';
import * as actions from '../../actions';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const User = ({ getUserData }) => {
  const [page, setPage] = useState(null);
  const pageRef = useRef();

  const scrollToRef = () => {
    let refTop = pageRef.current.getBoundingClientRect().top;

    if (window.innerWidth < 768) {
      if (window.scrollY < refTop) {
        window.scrollTo({ left: 0, top: refTop, behavior: 'smooth' });
      }
    } else window.scrollTo({ left: 0, top: refTop, behavior: 'smooth' });
  };

  const memoizedData = useCallback(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    memoizedData();
  }, [memoizedData]);

  const renderSection = section => {
    switch (section) {
      case 'Update':
        setPage(<Update />);
        break;
      case 'Past':
        setPage(<Past />);
        break;
      case 'New':
        setPage(<New />);
        break;
      default:
        setPage(<Update />);
    }
    scrollToRef();
  };

  return (
    <div>
      <div className='mt-25 mb-10'>
        <Redirect2Page />
      </div>
      <Container>
        <Row>
          <Col lg={4} sm={5} xs={12} className='mt-25 mb-60'>
            <Nav handleClick={renderSection} />
          </Col>
          <Col lg={8} sm={7} xs={12} className='mt-25 mb-60'>
            <section ref={pageRef}>{page}</section>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connect(null, actions)(User);
