import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import Redirect2Page from '../Helpers/Redirect2Page';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import * as actions from '../../actions';

// 1, 2, ve 3. asama gorsellerini <progressDisplay> e sirasiyla yerlestir
const progress = [
  <div className='flex'>
    <span className='txt-lg-2 txt-bold txt-green border-btm-sld'>1 </span>
    <span className='txt-xs txt-green border-btm-dsh no-pad pl-15 letter-sp-n2'>
      &nbsp;
    </span>
  </div>,
  <div className='flex'>
    <span className='txt-xs txt-green border-btm-dsh no-pad letter-sp-7'>
      &nbsp;
    </span>
    <span className='txt-lg-2 txt-bold txt-green border-btm-sld'>2</span>
    <span className='txt-xs txt-green border-btm-dsh no-pad letter-sp-7'>
      &nbsp;
    </span>
  </div>,
  <div className='flex'>
    <span className='txt-xs txt-green border-btm-dsh no-pad pl-15 letter-sp-n2'>
      &nbsp;
    </span>
    <span className='txt-lg-2 txt-bold txt-green border-btm-sld'>3</span>
  </div>
];

const SignUp = ({ getSurveyQuestions }) => {
  const [step, setStep] = useState(0);

  const memoizedData = useCallback(() => {
    getSurveyQuestions();
  }, [getSurveyQuestions]);

  useEffect(() => {
    memoizedData();
  }, [memoizedData]);

  const nextPage = () => {
    setStep(step + 1);
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };
  const previousPage = () => {
    setStep(step - 1);
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  const pages = [
    <Form1 onSubmit={nextPage} />,
    <Form2 onSubmit={nextPage} handlePrevious={previousPage} />,
    <Form3 handlePrevious={previousPage} />
  ];

  return (
    <div>
      <div className='mt-25 mb-n30'>
        <Redirect2Page page='/' />
      </div>
      <Container>
        <Row className='align-ctr mb-120'>
          <Col sm={6} xs={12}>
            <Row className='align-ctr'>
              <Col lg={11} xs={12} className='mt-n10'>
                <img
                  src='https://storage.googleapis.com/bukalemun-bucket/images/sign-up.png'
                  alt='resim'
                  width='100%'
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6} xs={12} className='mt-25'>
            <Row className='paper-lg mt-0 p-30 ml-0 mr-0'>
              <Col lg={11} xs={10}>
                <div className='txt-lg-2 txt-bold txt-ctr mr-n45'>kayıt ol</div>
              </Col>
              <Col lg={1} xs={2}>
                <span>{progress[step]}</span>
              </Col>
              {step === 0 && pages[0]}
              {step === 1 && pages[1]}
              {step === 2 && pages[2]}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connect(null, actions)(SignUp);
