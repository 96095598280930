import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const Landing = () => {
  return (
    <div className='back-lt-green pb-120'>
      <Container>
        <Row className='pt-80 justify-ctr'>
          <Col xs={12} className='txt-xl txt-bold txt-ctr'>
            yöntem
          </Col>
          <Col md={10} xs={11} className='txt-lg mt-25 txt-l'>
            Kayıt ol ve hemen işaret dili öğrenmeye başla. Eğitim videolarından
            sonra kendini test etmeyi unutma.
          </Col>
          <div className='mt-25'>
            <Row className='justify-ctr'>
              <Col xs={12}>
                <Row className='justify-even'>
                  <Col md={3} sm={7} xs={9} className='paper-lg'>
                    <div className='txt-3xl txt-bold-mx txt-white txt-ctr txt-in-circle'>
                      <span className='align-vsub'>1</span>
                    </div>
                    <div className='p-15'>
                      <img
                        src='https://storage.googleapis.com/bukalemun-bucket/images/all-hands.jpg'
                        alt='step'
                        width='100%'
                        height='100%'
                      />
                      <div className='pt-15 pb-25'>
                        <span className='txt-lg-2 txt-ctr txt-orng txt-bold-mx'>
                          kayıt ol
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} sm={7} xs={9} className='paper-lg'>
                    <div className='txt-3xl txt-bold-mx txt-white txt-ctr txt-in-circle'>
                      <span className='align-vsub'>2</span>
                    </div>
                    <div className='p-15'>
                      <img
                        src='https://storage.googleapis.com/bukalemun-bucket/images/studydesk.jpg'
                        alt='step'
                        width='100%'
                        height='100%'
                      />
                      <div className='pt-15 pb-25'>
                        <span className='txt-lg-2 txt-ctr txt-orng txt-bold-mx'>
                          öğrenmeye başla
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} sm={7} xs={9} className='paper-lg'>
                    <div className='txt-3xl txt-bold-mx txt-white txt-ctr txt-in-circle'>
                      <span className='align-vsub'>3</span>
                    </div>
                    <div className='p-15'>
                      <img
                        src='https://storage.googleapis.com/bukalemun-bucket/images/tst-step.png'
                        alt='step'
                        width='100%'
                        height='100%'
                      />
                      <div className='pt-15 pb-25'>
                        <span className='txt-lg-2 txt-ctr txt-orng txt-bold-mx'>
                          kendini test et
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Landing;
