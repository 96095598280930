import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Carousel from '../Helpers/Carousel';
import { testWrapper as wrapper } from '../Helpers/wrappers';
import { ShowAllBtn } from '../Helpers/helpers';
import * as actions from '../../actions';
import useTimeout from '../../hooks/useTimeout';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Tests = ({ testList, progress, getTest, history }) => {
  const [testCount, setTestCount] = useState(null);
  const [carousel, setCarousel] = useState(null);
  const timedOut = useTimeout(2500);
  const carouselStyle = !timedOut ? { visibility: 'hidden' } : {};

  useEffect(() => {
    if (!testList || !progress) return;
    setTestCount(testList.length);
    let wrappedData = testList.map(item =>
      wrapper(
        item,
        progress.incompleteTestId === item._id,
        progress.testIds.includes(item._id),
        getTest,
        history,
        progress.results
      )
    );
    setCarousel(
      <Carousel items={wrappedData} count={[3, 3, 4, 4]} infinite={true} />
    );
    //eslint-disable-next-line
  }, [testList]);

  return (
    <Container>
      <Row className='justify-btwn pt-50 pb-60'>
        <Col sm={4} xs={12}>
          <span className='txt-lg'>
            Testler
            <span className='txt-lg txt-gray'> ({testCount})</span>
          </span>
        </Col>
        <ShowAllBtn linkTo='/user/list/tests' txt='tüm testleri gör' />
        <Row className='ml-0 mr-0 mt-10 no-pad' style={carouselStyle}>
          {carousel}
        </Row>
      </Row>
    </Container>
  );
};

function mapStateToProps(data) {
  return { testList: data.testList, progress: data.progress };
}

export default connect(mapStateToProps, actions)(withRouter(Tests));
