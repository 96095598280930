import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { renderFlag, videoWrapper } from '../../../Helpers/wrappers';
import useCurrentPageItems from '../../../../hooks/useCurrentPageItems';
import usePageNav from '../../../../hooks/usePageNav';
import * as actions from '../../../../actions';

const PastVideos = ({ filteredVideos, progress, getVideo, history }) => {
  const progressCount = progress ? progress.videoCount : 0;
  const txtClass1 = 'txt-green txt-sm txt-bold txt-no-wrap';
  const txtClass2 = 'txt-lt-gray txt-sm txt-lt txt-no-wrap';
  const scoreFlag = renderFlag('video', progressCount, txtClass1, txtClass2);

  const vidWrapper = vid => {
    let isWatched = progress.videoIds.includes(vid._id);
    return (
      <Col xl={4} md={6} xs={12} key={vid._id}>
        {videoWrapper(vid, isWatched, getVideo, history)}
      </Col>
    );
  };

  const displayCount = 12;
  const { page, navButtons } = usePageNav(filteredVideos, displayCount);
  const renderList = useCurrentPageItems(
    'videos',
    filteredVideos,
    vidWrapper,
    progress,
    displayCount,
    page
  );

  return (
    <Row className='mb-25 justify-ctr'>
      <Col xl={4} lg={5} md={6} sm={8} xs={12} className='mb-n60 z-2'>
        {scoreFlag ? scoreFlag : null}
      </Col>
      <Col xs={12} className='paper back-lt-green no-pad pt-50 pb-40 mb-40'>
        <Row className='pt-50'>{renderList}</Row>
      </Col>
      <Col xs={12} className='txt-r'>
        {navButtons}
      </Col>
    </Row>
  );
};

function mapStateToProps(data) {
  return { progress: data.progress };
}

export default connect(mapStateToProps, actions)(withRouter(PastVideos));
