import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import Progress from './Progress';
import Videos from './Videos';
import Tests from './Tests';
// import Words from './Words';
import * as actions from '../../actions';
import { initializeGA } from '../Helpers/helpers';

const Profile = ({ getProfile }) => {
  initializeGA('Akış Sayfası');

  const memoizedCallback = useCallback(() => {
    getProfile();
  }, [getProfile]);

  useEffect(() => {
    memoizedCallback();
  }, [memoizedCallback]);

  return (
    <div>
      <div className='back-green-white mt-40'>
        <Progress />
      </div>
      <Videos />
      <div className='back-lt-green mt-40 mb-120'>
        <Tests />
      </div>
      {/* <div className='mt-40 mb-120'>
        <Words />
      </div> */}
    </div>
  );
};

export default connect(null, actions)(Profile);
