export const LOGIN = 'login';
export const PROGRESS = 'progress';
export const VIDEOLIST = 'videos';
export const USERDATA = 'userData';
export const TESTLIST = 'tests';
export const WORDLIST = 'wordList';
export const VIDEO = 'video';
export const TEST = 'test';
export const QUESTION = 'question';
export const RESULT = 'result';
export const LOGOUT = 'logout';
export const UPDATE_USER = 'updateUser';
export const SURVEY = 'survey';
export const FORGOT = 'forgotPassword';
export const NEWS = 'news';
export const SCORE = 'score';

// app wide
export const DIALOG = 'dialog';

// admin types
export const LOGIN_A = 'loginA';
export const POST_A = 'postA';
export const GET_A = 'getA';
