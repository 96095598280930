import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import trLocale from 'date-fns/locale/tr';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers/';

import KVKK from '../../data/kvkk';

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    borderRadius: 3,
    color: '#161211',
    '& input:valid:focus + fieldset': { borderColor: '#2d7373' },
    '& input:invalid:focus + fieldset': { borderColor: '#2d7373' },
    '& textarea:valid:focus + textarea + fieldset': { borderColor: '#2d7373' },
    '& textarea:invalid:focus + textarea + fieldset': { borderColor: '#2d7373' }
  },
  password: {
    fontSize: 14,
    borderRadius: 3,
    color: '#161211',
    '& input:valid:focus + div + fieldset': { borderColor: '#2d7373' }
  },
  passwordIcon: {
    fill: '#a0a0a0'
  },
  autoComplete: {
    fontSize: 14,
    borderRadius: 3,
    color: '#161211',
    '& input:valid:focus + div + fieldset': { borderColor: '#2d7373' },
    '& input:invalid:focus + div + fieldset': { borderColor: '#2d7373' }
  },
  date: {
    fontSize: 14,
    borderRadius: 3,
    color: '#161211',
    '& input:valid:focus + div + fieldset': { borderColor: '#2d7373' }
  }
}));

export const TextComponent = ({
  input,
  input: { onChange },
  label,
  meta: { error, initial },
  mLine = false,
  autoComplete = 'false',
  placeholder = '',
  rows = 1
}) => {
  const [touched, setTouched] = useState(false);
  const classes = useStyles();

  const onBlur = e => {
    e.target.value.trim();
    setTouched(true);
  };

  return (
    <FormControl variant='outlined' error={touched && error !== undefined}>
      <FormLabel>
        <span className='txt-bold'>{label}</span>
      </FormLabel>
      <OutlinedInput
        {...input}
        className={classes.text}
        notched={false}
        style={{ fontFamily: 'Poppins' }}
        placeholder={placeholder}
        multiline={mLine}
        autoComplete={autoComplete}
        type='text'
        defaultValue={initial}
        onBlur={e => onBlur(e)}
        rows={rows}
        rowsMax={rows}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export const PasswordComponent = ({
  input: { onChange },
  label,
  meta: { error },
  autoComplete = 'false'
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [touched, setTouched] = useState(false);
  const classes = useStyles();

  return (
    <FormControl variant='outlined' error={touched && error !== undefined}>
      <FormLabel>
        <span className='txt-bold'>{label}</span>
      </FormLabel>
      <OutlinedInput
        className={classes.password}
        notched={false}
        style={{ fontFamily: 'Poppins' }}
        autoComplete={autoComplete}
        type={showPassword ? 'text' : 'password'}
        onChange={e => onChange(e.target.value.trim())}
        onBlur={() => setTouched(true)}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={e => e.preventDefault()}
              edge='end'
            >
              {showPassword ? (
                <Visibility classes={{ root: classes.passwordIcon }} />
              ) : (
                <VisibilityOff classes={{ root: classes.passwordIcon }} />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const checkboxStyles = makeStyles({
  root: {
    '&:hover': {
      opacity: 0.7
    }
  },
  label: {
    fontSize: 14
  },
  icon: {
    borderRadius: 3,
    border: 'solid 1px #2d7373',
    width: 16,
    height: 16,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    'input:disabled:hover ~ &': {
      boxShadow: 'none',
      background: '#ffffff',
      border: 'solid 1px #a0a0a0'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: '#ffffff',
      border: 'solid 1px #a0a0a0'
    }
  },
  checkedIcon: {
    backgroundColor: '#ffffff',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='2 2 14 13'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%232d7373'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#ffffff'
    }
  }
});

export const CheckboxComponent = ({
  input: { onChange },
  label,
  checked = false,
  disabled = false
}) => {
  const [state, setState] = useState(checked);
  const classes = checkboxStyles();

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  const handleChange = () => {
    setState(!state);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          disableRipple
          disabled={disabled}
          checked={state}
          onClick={() => setState(!state)}
          onChange={handleChange}
        />
      }
      classes={{ root: classes.root, label: classes.label }}
      label={label}
    />
  );
};

function ConfirmationDialogRaw({ onClose, open, ...other }) {
  const handleOk = () => onClose(true);
  const handleCancel = () => onClose(false);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='lg'
      open={open}
      {...other}
    >
      <DialogContent>
        <KVKK />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>İPTAL</Button>
        <Button onClick={handleOk}>KABUL EDİYORUM</Button>
      </DialogActions>
    </Dialog>
  );
}

export const ConfirmationDialog = ({ input: { onChange }, label }) => {
  const [value, setValue] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = checkboxStyles();

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  const handleChange = () => setOpen(true);
  const handleClose = newValue => {
    setOpen(false);
    setValue(newValue);
  };

  return (
    <section>
      <FormControlLabel
        control={
          <Checkbox
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            icon={<span className={classes.icon} />}
            checked={value}
            onChange={handleChange}
            disableRipple
          />
        }
        classes={{ root: classes.root, label: classes.label }}
        label={label}
      />
      <ConfirmationDialogRaw keepMounted open={open} onClose={handleClose} />
    </section>
  );
};

export const StudentCheckbox = ({
  input: { onChange },
  checked = false,
  disabled = false
}) => {
  const [state, setState] = useState(checked);

  const icon = (
    <Row className='btn-full-2 align-ctr'>
      <Col
        sm={{ span: 11, offset: 1 }}
        xs={{ span: 9, offset: 2 }}
        className='align-r'
        style={{ lineHeight: '0.8' }}
      >
        <span className='txt-md'>öğrenmek istiyorum</span>
      </Col>
    </Row>
  );

  const checkedIcon = (
    <Row className='btn-full-2-ch align-ctr p-t5 p-b5'>
      <Col
        sm={{ span: 11, offset: 1 }}
        xs={{ span: 9, offset: 2 }}
        className='align-r'
        style={{ lineHeight: '0.8' }}
      >
        <span className='txt-green'>öğrenmek istiyorum</span>
      </Col>
    </Row>
  );

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  const handleChange = () => {
    setState(!state);
  };

  return (
    <FormControl className='align-l'>
      <Checkbox
        checkedIcon={checkedIcon}
        icon={icon}
        disableRipple
        disabled={disabled}
        checked={state}
        onChange={handleChange}
        onClick={() => setState(!state)}
        style={{ padding: '0px', marginLeft: '0px', width: '100%' }}
      />
    </FormControl>
  );
};

export const SelectOneComponent = ({
  input: { onChange, value },
  meta: { error, initial },
  label,
  list,
  placeholder = ''
}) => {
  const [touched, setTouched] = useState(false);
  const classes = useStyles();

  const icon = <RadioButtonUncheckedIcon fontSize='small' />;
  const checkedIcon = <RadioButtonCheckedIcon fontSize='small' />;

  return (
    <FormControl
      className='align-ttop'
      style={{ marginTop: '1px' }}
      variant='outlined'
      error={touched && error !== undefined}
    >
      <FormLabel>
        <span className='txt-bold'>{label}</span>
      </FormLabel>
      <Autocomplete
        className={classes.autoComplete}
        multiple={false}
        options={list}
        disableClearable
        defaultValue={initial}
        value={value ? value : null}
        onChange={(event, values) => onChange(values)}
        onClose={() => setTouched(true)}
        getOptionLabel={option => option}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
              disableRipple
              style={{
                whiteSpace: 'normal'
              }}
            />
            {option}
          </React.Fragment>
        )}
        renderInput={params => (
          <TextField
            {...params}
            style={{ fontFamily: 'Poppins' }}
            placeholder={touched ? '' : placeholder}
            variant='outlined'
            error={touched && error !== undefined}
          />
        )}
      />
    </FormControl>
  );
};

export const SelectMultiComponent = ({
  input: { onChange, value },
  meta: { error, initial },
  label = '',
  list,
  placeholder = ''
}) => {
  const [touched, setTouched] = useState(false);
  const classes = useStyles();

  const icon = (
    <CheckBoxOutlineBlankIcon
      fontSize='small'
      style={{ verticalAlign: 'text-top' }}
    />
  );
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  return (
    <FormControl variant='outlined' error={touched && error !== undefined}>
      <FormLabel>
        <span className='txt-bold'>{label}</span>
      </FormLabel>
      <Autocomplete
        className={classes.autoComplete}
        multiple
        limitTags={1}
        options={list}
        disableCloseOnSelect
        disableClearable
        defaultValue={initial}
        value={value ? value : []}
        onChange={(event, values) => onChange(values)}
        onClose={() => setTouched(true)}
        getOptionLabel={option => option}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
              disableRipple
              style={{ whiteSpace: 'normal' }}
            />
            {option}
          </React.Fragment>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={touched ? '' : placeholder}
            style={{ fontFamily: 'Poppins' }}
            variant='outlined'
            error={touched && error !== undefined}
          />
        )}
      />
    </FormControl>
  );
};

export const DateComponent = ({
  meta: { submitting, error, touched },
  input: { onBlur, value, ...inputProps },
  label,
  ...others
}) => {
  const onChange = date => {
    Date.parse(date)
      ? inputProps.onChange(date.toISOString())
      : inputProps.onChange(null);
  };
  const classes = useStyles();

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
        <FormLabel>
          <span className='txt-bold'>{label}</span>
        </FormLabel>
        <div id='Date-picker'>
          <KeyboardDatePicker
            {...inputProps}
            {...others}
            className={classes.date}
            style={{ fontFamily: 'Poppins' }}
            placeholder='__/__/____'
            autoOk
            disableToolbar
            disableFuture
            openTo='year'
            okLabel='TAMAM'
            cancelLabel='İPTAL'
            inputVariant='outlined'
            format='dd/MM/yyyy'
            maxDate={Date('1900/01/01')}
            value={value ? new Date(value) : null}
            disabled={submitting}
            onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
            error={error && touched}
            onChange={onChange}
          />
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};
