import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as actions from '../../actions';

const VideoPlayer = ({ data, postWatched }) => {
  const [videoURL, setVideoURL] = useState(null);
  const [title, setTitle] = useState(null);
  let reqSent = false;

  const ended = state => {
    if (!reqSent && state.played >= 0.8) {
      postWatched(data);
      reqSent = true;
    }
  };

  useEffect(() => {
    if (!data) return;
    setVideoURL(data.url);
    setTitle(data.title);
  }, [data]);

  return (
    <Row>
      <Col xs={12}>
        <div className='txt-lg-2 txt-bold pb-15'>{title}</div>
        <ReactPlayer
          url={videoURL}
          width='100%'
          controls={true}
          onProgress={ended}
          className='back-lt-green'
        />
      </Col>
    </Row>
  );
};

function mapStateToProps(data) {
  return { data: data.video };
}

export default connect(mapStateToProps, actions)(VideoPlayer);
