import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  text: {
    fontSize: 14,
    borderRadius: 3,
    color: '#161211',
    '& input:valid:focus + div + fieldset': { borderColor: '#2d7373' }
  },
  icon: {
    borderRadius: 3,
    border: 'solid 1px #2d7373',
    width: 18,
    height: 18,
    backgroundColor: '#ffffff',
    marginBottom: '2px',
    marginLeft: '2px'
  },
  checkedIcon: {
    backgroundColor: '#2d7373'
  }
});

const SearchFilter = ({ contentType, handleSearch }) => {
  const [checked, setChecked] = useState(false);
  const [searchParams, setSearchParams] = useState('');
  const classes = useStyles();

  const style = {
    color: '#f27244',
    fontSize: '30px',
    marginRight: '-10px',
    outline: 'none'
  };

  let hideBtnText;
  contentType === 'tests'
    ? (hideBtnText = 'çözdüklerimi gizle')
    : (hideBtnText = 'izlediklerimi gizle');

  const search = () => handleSearch(searchParams, checked);
  const handleKeyEvent = e => {
    if (e.key === 'Enter') search();
  };

  useEffect(() => {
    search();
    //eslint-disable-next-line
  }, [checked]);

  return (
    <Container>
      <Row className='justify-btwn align-ctr mt-40 mb-40'>
        <Col xl={5} lg={6} md={7} sm={8} xs={12} className='mt-25'>
          <FormControl variant='outlined'>
            <OutlinedInput
              onChange={e => setSearchParams(e.target.value.trim())}
              placeholder='Aradığın kelimeyi yaz.'
              className={classes.text}
              style={{ fontFamily: 'Poppins' }}
              value={searchParams}
              onKeyPress={handleKeyEvent}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton onClick={search}>
                    <SearchIcon style={style} />
                  </IconButton>
                </InputAdornment>
              }
              notched={false}
              type='text'
            />
          </FormControl>
        </Col>
        <Col sm='auto' xs={12} className='mt-25'>
          <div
            onClick={() => setChecked(!checked)}
            className='btn-lt-green btn-md-sh center-hr'
            id='Gizle'
          >
            <Checkbox
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              disableRipple
              checked={checked}
              color='primary'
            />
            <span>{hideBtnText}</span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchFilter;
