import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import smoothscroll from 'smoothscroll-polyfill';

import App from './components/App';
import reducers from './reducers';

smoothscroll.polyfill();

let store;
if (process.env.NODE_ENV === 'production') {
  store = createStore(reducers, {}, applyMiddleware(reduxThunk));
} else {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(
    reducers,
    {},
    composeEnhancers(applyMiddleware(reduxThunk))
  );
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#root')
);
