import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import useCurrentPageItems from '../../hooks/useCurrentPageItems';
import usePageNav from '../../hooks/usePageNav';
import * as actions from '../../actions';
import {
  videoWrapper,
  testWrapper,
  wordWrapper,
  newsWrapper
} from '../Helpers/wrappers';

/**
 * only for video, test & word contentTypes
 * @param contentType videos || tests || words
 */
const Results = ({
  contentType,
  results,
  progress,
  history,
  getTest,
  getVideo,
  showPageNav = true
}) => {
  const vidWrapper = vid => {
    let isWatched = progress.videoIds.includes(vid._id);
    return (
      <Col lg={3} md={4} sm={6} xs={12} key={vid._id} className='no-pad'>
        {videoWrapper(vid, isWatched, getVideo, history)}
      </Col>
    );
  };
  const tstWrapper = test => {
    let isIncomplete = progress.incompleteTestId === test._id;
    let isCompleted = progress.testIds.includes(test._id);
    return (
      <Col lg={3} md={4} sm={6} xs={12} key={test._id} className='no-pad'>
        {testWrapper(
          test,
          isIncomplete,
          isCompleted,
          getTest,
          history,
          progress.results
        )}
      </Col>
    );
  };
  const wrdWrapper = word => {
    return (
      <Col lg={3} md={4} sm={6} xs={12} key={word._id} className='no-pad'>
        {wordWrapper(word, getVideo, history)}
      </Col>
    );
  };

  let wrapper;
  if (contentType === 'videos') wrapper = vidWrapper;
  else if (contentType === 'tests') wrapper = tstWrapper;
  else if (contentType === 'words') wrapper = wrdWrapper;
  else wrapper = newsWrapper;

  const { page, navButtons } = usePageNav(results, 12);
  const renderList = useCurrentPageItems(
    contentType,
    results,
    wrapper,
    progress,
    12,
    page
  );

  return (
    <Container>
      <Row className='paper ml-0 mr-0 pt-25 mb-120'>
        <Col xs={12}>
          <Row>{renderList}</Row>
        </Col>
        <Col xs={12} className='txt-r pt-15 pb-25'>
          {showPageNav && navButtons}
        </Col>
      </Row>
    </Container>
  );
};

function mapStateToProps(data) {
  return { progress: data.progress };
}

export default connect(mapStateToProps, actions)(withRouter(Results));
